import React from 'react';

import MenuModel from '../MenuModel';
import BasicInfoForm from './BasicInfoForm';
import MenuCategoryForm from './MenuCategoryForm';
import MenuSubCategoryForm from './MenuSubCategoryForm';
import MenuItemForm from './MenuItem/MenuItemForm';

const FormHandler = props => {
  const {
    menuBuilderState,
    menuBuilderGetMenu,
    menuBuilderLogIn,
    menuBuilderSetCategory,
    setFormHandlerStep,
    menuBuilderSetCurrent,
    menuBuilderSetSubCategory,
    menuBuilderSetItem,
    // menuBuilderDeleteItem,
    menuLoaded,
    editingCategory,
    storeName
  } = props;

  const {
 current, modifiersLoaded, formStep, editingSubCategory
} = menuBuilderState;

  switch (formStep) {
    case 0:
      return <BasicInfoForm menuBuilderLogIn={menuBuilderLogIn} menuBuilderGetMenu={menuBuilderGetMenu} />;
    case 1:
      return (
        <MenuCategoryForm
          menuBuilderSetCategory={menuBuilderSetCategory}
          menuBuilderSetCurrent={menuBuilderSetCurrent}
          menuLoaded={menuLoaded}
          editingCategory={editingCategory}
          storeName={storeName}
        />
      );
    case 2: {
      const category = menuBuilderState.categories[current.category].name;
      return (
        <MenuSubCategoryForm
          category={category}
          editingSubCategory={editingSubCategory}
          menuBuilderSetSubCategory={menuBuilderSetSubCategory}
          menuBuilderSetCurrent={menuBuilderSetCurrent}
        />
      );
    }
    case 3: {
      const currentItem = menuBuilderState.categories[current.category].subCategories[current.subCategory].items[current.item];
      const itemModel = JSON.parse(JSON.stringify(MenuModel.categories[0].subCategories[0].items[0]));
      const item = currentItem || itemModel;
      const category = menuBuilderState.categories[current.category].name;
      const subCategory = menuBuilderState.categories[current.category].subCategories[current.subCategory].name;
      return (
        <MenuItemForm
          category={category}
          subCategory={subCategory}
          modifiersLoaded={modifiersLoaded}
          item={item}
          setFormHandlerStep={setFormHandlerStep}
          menuBuilderSetItem={menuBuilderSetItem}
          // menuBuilderDeleteItem={menuBuilderDeleteItem}
        />
      );
    }
    case 4:
      return null;
    default:
      throw new Error('Form handler state is not valid');
  }
};

export default FormHandler;
