import React, { Component } from 'react';

export default class DropDown extends Component {
  state = { name: this.props.name, value: this.props.value };

  handleOption = async ({ target }) => {
    const { handleDropDown } = this.props;
    const value = target.value === 'NONE' ? 'NONE' : Number(target.value);
    await this.setState({ value });
    handleDropDown(this.state);
  };

  render() {
    const { length, name, label } = this.props;
    const { value } = this.state;
    const options = [];

    if (name === 'course' || name === 'labelPrinter') {
      for (let i = 0; i < length; i += 1) options.push(i);
      options[0] = 'NONE';
    } else {
      for (let i = 0; i < length; i += 1) options.push(i + 1);
    }

    return (
      <div className="mb-2">
        <select value={value || 0} className="browser-default custom-select" onChange={this.handleOption}>
          {options.map(optionValue => (
            <option key={optionValue} name={name} value={optionValue}>
              {label} {optionValue === 'NONE' ? '' : '#'}
              {optionValue}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

DropDown.defaultProps = {
  checked: false
};
