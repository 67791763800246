import React, { Component } from "react";
import { BrowserRouter as  Link } from 'react-router-dom';
// import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
// import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ServiceModalPage from './service_modal';


class ServicePage extends Component {
render() {
    const services = [
        {
            id: 1,
            title: "bakin' sumadat hayyumm",
            description: "come on a slam, and honey bake this ham",
            price: "FREE NINETY NINE???"
        },
        {
            id: 2,
            title: "BAAAAYYYKKEDDDDDD BAAAAAAYNNESSSSS",
            description: "come on a slam, and money rake this scam",
            price: "FREE NINETY NINE???"
        },
        {
            id: 3,
            title: "BIG JUDY DOODEE",
            description: "come on a slam, and dummy fake this man",
            price: "FREE NINETY NINE???"
        }
    ];
  return (
  	<div>
	  	<Container>
		  	<h1 style={{ marginBottom: '2rem', marginTop: '2rem' }}className="text-center">Select a Service</h1>
		  	<hr />
            {services.map((service, index) => (
    			<Card key={index} className="text-center">
                    <Card.Body>
                        <Card.Title> {service.title} </Card.Title>
                        <Card.Text> {service.description} </Card.Text>
                        <Card.Footer> {service.price} </Card.Footer>
                        <div className="text-right">
                        <Link to={"/service"}>
                            <ServiceModalPage />
                        </Link>
                        </div>
                    </Card.Body>
                </Card>
            ))}
                <div className="text-right">
    				<Link to={"/schedule"}>
    					<Button variant="success">Next</Button>
    		  		</Link>
    	  		</div>
	  	</Container>  
	</div>
    );
  }
}

export default ServicePage;


