const storeGetParser = stores => {
    const storeCategories = [];
    console.log("storeParser.js", stores);
    stores.forEach((store, storeCategoryIndex) => {
      const storeTitle = store.default_url;
      const storeAddress = store.store_address;
      const storeName = store.name;
      if (storeTitle) {
        storeCategories.push({
          title: storeTitle,
          name: storeName,
          address: storeAddress
        });
      }
    });
    return storeCategories;
  };
  
  export default storeGetParser;
