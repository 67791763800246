import React, { Component, } from "react"
import { BrowserRouter as  Link } from 'react-router-dom';
// import DatePickerPage from './datepicker';
import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import FormCheck from 'react-bootstrap/FormCheck';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';


class SchedulePage extends Component {
	render() {
		return(
			<Container style={{ marginTop: "2rem" }}>
			<h1 style={{ marginBottom: '2rem', marginTop: '2rem' }}className="text-center">Schedule Appointment</h1>
			<hr />
				<Form>
					<Form.Row>
						<Form.Group as={Col} controlId="formGridFName">
							<Form.Label>First Name</Form.Label>
							<Form.Control placeholder="First Name" />
						</Form.Group>

						<Form.Group as={Col} controlId="formGridLName">
							<Form.Label>Last Name</Form.Label>
							<Form.Control placeholder="Last Name" />
						</Form.Group>
					</Form.Row>
					<Form.Row>
							<Form.Label>Date(will soon be a date picker)</Form.Label>
							<input type="date" className="form-control"/>
					</Form.Row>
					<Form.Row>
						<Form.Group as={Col} controlId="formGridEmployee">
								<Form.Label>Employee</Form.Label>
								<Form.Control as="select">
									<option>John</option>
									<option>Jacob</option>
									<option>Jingle</option>
									<option>Heimer</option>
									<option>Schmidt</option>
								</Form.Control>
						</Form.Group>
					</Form.Row>
					<Link to="/confirmation">
						<Button variant="primary" type="submit">Submit</Button>
					</Link>
				</Form>
			</Container>
		);
	}
}

export default SchedulePage;