// import axios from 'axios';
// import storeGetParser from  './storeParser';



import axios from 'axios';
import storeGetParser from './storeParser'
// import {
//  itemGetParser, itemPostParser, modifierGetParser, logInParser
// } from './menuParser';

const axiosConfig = {
  headers: {
    Authorization: null
  },
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'DELETE, GET, OPTIONS, PATCH, POST, PUT',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
};

export default {
  async getStores() {
    const { data } = await axios.get('/api/reservations/get/stores/', axiosConfig);
    console.log('ResAPI Res... ', data)
    return storeGetParser(data);
  }
  // async getItems() {
  //   const { data } = await axios.get('/api/menu/get/items/', axiosConfig);
  //   return itemGetParser(data);
  // },
  // async getModifiers(itemId) {
  //   const { data } = await axios.get(`/api/menu/get/modifiers/${itemId}`, axiosConfig);
  //   return modifierGetParser(data);
  // },
  // async getAllItemComments() {
  //   const { data } = await axios.get('/api/menu/get/all-item-comments', axiosConfig);
  //   return data;
  // },
  // async updateCategory(oldCategory, newCategory) {
  //   await axios.put('/api/menu/update/category', { oldCategory, newCategory }, axiosConfig);
  // },
  // async updateSubCategory(currentCategory, oldSubCategory, newSubCategory) {
  //   await axios.put('/api/menu/update/sub-category', { currentCategory, oldSubCategory, newSubCategory }, axiosConfig);
  // },
  // async updateItem(category, subCategory, item, itemId) {
  //   const { parsedItem /* parsedModifierTwo, parsedModifierThree */ } = itemPostParser(
  //     category,
  //     subCategory,
  //     item
  //   );
  //   console.log(parsedItem)
  //   await axios.put(`/api/menu/update/item/${itemId}`, { parsedItem }, axiosConfig);
    // await axios.put('/api/menu/update/modifier-two', { parsedModifierTwo });
    // await axios.put('/api/menu/update/modifier-three', { parsedModifierThree });
  // },
  // async postItem(category, subCategory, item) {
  //   const { parsedItem, parsedModifierTwo, parsedModifierThree } = itemPostParser(
  //     category,
  //     subCategory,
  //     item
  //   );
  //   await axios.post('/api/menu/post/item', { parsedItem }, axiosConfig);
  //   await axios.post('/api/menu/post/modifier-two', { parsedModifierTwo }, axiosConfig);
  //   await axios.post('/api/menu/post/modifier-three', { parsedModifierThree }, axiosConfig);
  // },
  // // async deleteItem(itemId) {
  // //   await axios.delete(`/api/menu/destroy/item/${itemId}`);
  // // },
  // async logIn(user) {
  //   const { data } = await axios.get(`/api/menu/login/${user.userName}/${user.passWord}`);
  //   return logInParser(data);
  // },
  // async logInToStore(logInInfo) {
  //   const { data } = await axios.get(`/api/menu/login/store/${logInInfo.clientId}/${logInInfo.storeName}`);
  //   const { storeName, token } = data;
  //   axiosConfig.headers.Authorization = token;
  //   return storeName;
  // },
  // async updateUserLogInTime(clientId) {
  //   await axios.put('/api/menu/update/log-in-time', { clientId });
  // },
  // async updateUserLocation(locationInfo) {
  //   await axios.put('/api/menu/update/location', { locationInfo });
  // }
  // async disconnect() {
  //   await axios.get('/api/menu/logout');
  // }
};
