import React, { Component } from 'react';
import {
 MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBInput
} from 'mdbreact';

const styles = {
  heading: {
    fontWeight: '500'
  },
  btn: {
    fontWeight: '900',
    fontSize: '1.2rem'
  },
  input: {
    fontWeight: '700',
    fontSize: '1.3rem'
  }
};

class BasicInfoForm extends Component {
  state = {
    userName: '',
    passWord: '',
    coords: null
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  submit = async e => {
    e.preventDefault();
    const { menuBuilderLogIn } = this.props;
  
    menuBuilderLogIn(this.state);
  };

  render() {
    const { userName, passWord } = this.state;
    return (
      <MDBCard style={styles.card}>
        <MDBCardBody>
          <form>
            <h2 className="text-center text-info py-4" style={styles.heading}>
              AST POS Menu Portal
            </h2>
            <MDBInput
              label="Aura username"
              size="lg"
              icon="user"
              group
              type="text"
              success="right"
              name="userName"
              value={userName}
              onChange={this.handleChange}
              style={styles.input}
            />
            <br />
            <MDBInput
              label="Aura password"
              size="lg"
              icon="key"
              group
              type="password"
              name="passWord"
              success="right"
              value={passWord}
              onChange={this.handleChange}
              style={styles.input}
            />
            <div className="text-center py-4 mt-3">
              <MDBBtn outline color="info" type="submit" onClick={this.submit} style={styles.btn}>
                Login
                <MDBIcon icon="sign-in-alt" className="ml-2" size="lg" />
              </MDBBtn>
            </div>
          </form>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default BasicInfoForm;
