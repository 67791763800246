import React from 'react';
import {
 MDBCol, MDBBtn, MDBModal, MDBModalBody
} from 'mdbreact';
import GoogleMapReact from 'google-map-react';

const styles = {
  restaurantBtn: {
    fontSize: '1.2rem'
  }
};

const LogInModal = props => {
  const toggle = () => {};

  const { userInfo, menuBuilderLogInToStore, error } = props;
  const { storeNames, oldLocation, newestLogIn } = userInfo;
  let center;
  if (oldLocation) {
    const oldLocationCoords = oldLocation.split(':');
    center = { lat: Number(oldLocationCoords[0]), lng: Number(oldLocationCoords[1]) };
  }

  const renderMarkers = (map, maps) => new maps.Marker({
      position: center,
      map,
      title: 'Last Log-In'
    });
  if (error) {
    return (
      <MDBModal isOpen toggle={toggle}>
        <MDBModalBody>
          <MDBCol>
            <div className="text-center">
              <h2 className="deep-orange-text font-weight-bold pt-4 pb-2">There seems to be an issue.</h2>
              <br />
              <h4 className="text-info font-weight-bold pt-2 pb-4">
                Please reload the webpage and try again, or report this issue.
              </h4>
              <MDBBtn
                color="info"
                style={styles.restaurantBtn}
                className=" my-2"
                onClick={() => {
                  props.closeLoginMenu();
                }}
              >
                Close
              </MDBBtn>
              <MDBBtn
                color="info"
                style={styles.restaurantBtn}
                className=" my-2"
                onClick={() => {
                  console.log(error)
                  // window.location.reload(true);
                }}
              >
                Reload
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBModalBody>
      </MDBModal>
    );
  }
  return (
    <MDBModal isOpen toggle={toggle}>
      <MDBModalBody>
        <MDBCol>
          {storeNames ? (
            <>
              <h2 className="text-center deep-orange-text font-weight-bold pt-4 pb-2">
                Please choose a location
              </h2>
              <br />
              {storeNames.map((storeName, index) => (
                <MDBBtn
                  key={index}
                  color="info"
                  style={styles.restaurantBtn}
                  className="px-2 py-2 my-2"
                  onClick={() => {
                    menuBuilderLogInToStore({ storeName });
                  }}
                >
                  {storeName}
                </MDBBtn>
              ))}                          
              <hr />
              <h2 className="text-center deep-orange-text font-weight-bold py-4">Last Login</h2>
              <h5 className="text-center text-info font-weight-bold pt-2 pb-4">{newestLogIn} UTC</h5>
              <hr />
              {oldLocation ? (
                <div
                  id="map-container-google-1"
                  className="z-depth-1-half"
                  style={{ height: '20vh', width: '100%' }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyAVn10_-SYMvRD4Z6rwvQX9qqWsy5E9vAg' }}
                    defaultCenter={center}
                    defaultZoom={15}
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                  />
                </div>
              ) : (
                <h2 className="text-center deep-orange-text font-weight-bold py-4">No location available</h2>
              )}
            </>
          ) : (
            <h2 className="text-center py-4 deep-orange-text">
              Retrieving user information...
              <br />
              <br />
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </h2>
          )}
        </MDBCol>
      </MDBModalBody>
    </MDBModal>
  );
};

export default LogInModal;
