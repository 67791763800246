import React from 'react';

import Card from 'react-bootstrap/Card';

// import NumericInput from 'react-numeric-input';

// import Button from 'react-bootstrap/Button';


const serviceModifiers = [
	{
		id: 1,
		title: "service modifier #1"
	},

	{
		id: 2,
		title: "service modifier #2"
	},

	{
		id: 3,
		title: "service modifier #3"
	},

	{
		id: 4,
		title: "service modifier #4"
	}
    
];

const ServiceModifierPage = () => {
  return (
	<div>
	{serviceModifiers.map((serviceModifier, index) => (
		<Card.Body key={index}>
			{serviceModifier.title}
		</Card.Body>
		))};
	</div>
  );
}



export default ServiceModifierPage;