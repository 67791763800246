import React, { Component } from 'react';
// All styling in App.css

export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    const {
 name, checked, modalIndex
} = props;
    this.state = {
      name,
      checked,
      modalIndex: modalIndex || null
    };
  }

  changeCheck = async () => {
    const { handleCheckBox } = this.props;
    await this.setState(state => ({
      checked: !state.checked
    }));
    handleCheckBox(this.state);
  };

  render() {
    const { props } = this;

    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`${props.label}-checkbox`}
          onChange={this.changeCheck}
          defaultChecked={props.checked}
        />
        <label className="custom-control-label" htmlFor={`${props.label}-checkbox`}>
          {props.label}
        </label>
      </div>
    );
  }
}

CheckBox.defaultProps = {
  checked: false
};
