/* eslint-disable consistent-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MDBRow, MDBCol, MDBAlert } from 'mdbreact';

import './MenuBuilder.css';

import AuthModal from '../../components/menu/AuthModal';
import LogInModal from '../../components/menu/LogInModal';
import FormHandler from '../../components/menu/FormHandler/FormHandler';
import NavHandler from '../../components/menu/NavHandler/NavHandler';

import MenuAPI from '../../utils/menu/MenuAPI';

class MenuBuilder extends Component {
  state = {
    formStep: 0,
    menuLoaded: false,
    clientId: null,
    logInInfo: null,
    alert: {},
    logInModal: false,
    twoFA: false,
    userInfo: {},
    newLocation: null,
    storeName: '',
    modifiersLoaded: false,
    error: false,
    current: { category: null, subCategory: null, item: null },
    editingCategory: null,
    editingSubCategory: null,
    categories: [],
    allItemCommentsLoaded: false,
    allItemComments: [],
    sideNavCollapse: false,
    categoryDisplay: [],
    subCategoryDisplay: [],
    itemDisplay: [],
    addSubCategoryBtn: false
  };

  formHandlerRef = React.createRef();

  componentDidMount() {
    this.getLocation();
  }

  componentDidUpdate() {
    const { error, logInModal } = this.state;
    if (error && !logInModal) {
      this.setState({ logInModal: true });
    }
  }

  getLocation = () => {
    const success = ({ coords: { latitude, longitude } }) => {
      const coords = String(latitude).concat(':', String(longitude));
      this.setState({ newLocation: coords });
    };
    const error = () => {};
    const options = {
      enableHighAccuracy: true,
      timeout: 100000,
      maximumAge: 0
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  // eslint-disable-next-line react/destructuring-assignment
  menuBuilderLogIn = async (logInInfo = this.state.logInInfo, verified = true) => {
    if (logInInfo.userName && logInInfo.passWord) {
      this.setState({ logInInfo });
      try {
        if (verified) {
          this.setState({ logInModal: true });
          const {
 storeNames, clientId, oldLocation, newestLogIn
} = await MenuAPI.logIn(logInInfo);
          if (storeNames) {
            this.setState({
              userInfo: {
                clientId,
                storeNames,
                oldLocation,
                newestLogIn
              }
            });
          } else {
            this.setState({ error: 'LogIn no Store Names' });
          }
        } else if (await this.checkForAuth()) {
          this.setState({ twoFA: true });
        } else {
          this.setState({ logInModal: true });
          const {
 storeNames, clientId, oldLocation, newestLogIn
} = await MenuAPI.logIn(logInInfo);
          if (storeNames) {
            this.setState({
              userInfo: {
                clientId,
                storeNames,
                oldLocation,
                newestLogIn
              }
            });
          } else {
            this.setState({ error: 'LogIn no Store Names' });
          }
        }
      } catch (error) {
        this.setState({ error: `LogIn => ${error}` });
      }
    } else {
      this.setState({ error: 'No username and password provided' });
    }
  };

  menuBuilderLogInToStore = async logInInfo => {
    if (logInInfo.storeName) {
      try {
        const { userInfo, newLocation } = this.state;
        const { clientId } = userInfo;
        const locationInfo = { newLocation, clientId };
        MenuAPI.updateUserLocation(locationInfo);
        MenuAPI.updateUserLogInTime(clientId);
        logInInfo.clientId = clientId;
        const storeName = await MenuAPI.logInToStore(logInInfo);
        this.setState({ storeName, logInModal: false, userInfo: {} });
        this.setFormHandlerStep(1);
        this.menuBuilderGetMenu();
      } catch (error) {
        this.setState({ error: `LogIn To Store => ${error}` });
      }
    } else {
      this.setState({ error: 'No a valid store name' });
    }
  };

  menuBuilderGetMenu = async () => {
    try {
      const categories = await MenuAPI.getItems();
      this.setState({ menuLoaded: true, categories });
      this.setFormHandlerStep(4);
    } catch (error) {
      this.setState({ menuLoaded: false, error: `Items => ${error}` });
    }
  };

  mapAndSet = (field, categoryIndex) => {
    const { categories, current } = this.state;
    categoryIndex = categoryIndex || current.category;
    this.menuBuilderReset('modifiersLoaded');
    if (field === 'categories') {
      this.setState({ categoryDisplay: [], subCategoryDisplay: [], itemDisplay: [] });
      const catArr = categories.map(({ name }) => name);
      this.setState({ categoryDisplay: [...catArr] });
      this.menuBuilderSetCurrent('subCategory', null);
    } else if (field === 'subCategories') {
      this.setState({ subCategoryDisplay: [], itemDisplay: [] });
      if (categories[categoryIndex].subCategories.length === 1) {
        const onlySubCategory = categories[categoryIndex].subCategories[0].name;
        if (onlySubCategory !== 'NONE') {
          this.setState({ subCategoryDisplay: [onlySubCategory] });
        }
        this.setState({ addSubCategoryBtn: true });
        this.menuBuilderSetCurrent('subCategory', 0);
        this.itemDisplay(0);
      } else {
        const subCatArr = categories[categoryIndex].subCategories.map(({ name }) => name);
        this.setState({ subCategoryDisplay: [...subCatArr], addSubCategoryBtn: true });
        this.menuBuilderSetCurrent('subCategory', null);
      }
    }
  };

  itemDisplay = subCategoryIndex => {
    const { current, categories } = this.state;
    const itemArr = categories[current.category].subCategories[subCategoryIndex].items.map(
      ({ name }) => name
    );
    this.setState({ itemDisplay: [...itemArr] });
  };

  displayCategories = newCategory => {
    this.setState({ sideNavCollapse: false });
    this.mapAndSet('categories');
    this.setFormHandlerStep(1);
    this.menuBuilderSetCurrent('subCategory', null);
    this.menuBuilderSetCurrent('item', null);
    if (newCategory) {
      const { categories } = this.state;
      const categoriesLength = categories.length;
      this.menuBuilderSetCurrent('category', categoriesLength);
    }
  };

  menuBuilderSetCategory = newCategory => {
    const { categories, editingCategory, current } = this.state;
    if (current.category === categories.length) {
      const categoryModel = [{ name: newCategory, subCategories: [] }];
      this.setState(state => ({
        categories: [...state.categories, ...categoryModel]
      }));
      const lastCategoryIndex = categories.length - 1;
      this.menuBuilderSetCurrent('category', lastCategoryIndex);
    } else if (editingCategory) {
      try {
        const oldCategory = categories[current.category].name;
        MenuAPI.updateCategory(oldCategory, newCategory);
        this.setState({ editingCategory: null, categoryDisplay: [newCategory], sideNavCollapse: false });
        this.setState(state => (state.categories[current.category].name = newCategory));
        this.alert('success', 'Category Updated');
      } catch (error) {
        this.setState({ error: `Update Category => ${error}` });
      }
    }
    this.setFormHandlerStep(4);
  };

  menuBuilderEditCategory = categoryIndex => {
    const { sideNavCollapse, current, categories } = this.state;
    this.setState({ sideNavCollapse: false });
    if (sideNavCollapse) {
      const { category } = current;
      const currentCategoryIndex = category;
      this.menuBuilderSetCurrent('category', currentCategoryIndex);
      this.setState({ editingCategory: categories[currentCategoryIndex].name });
    } else {
      this.menuBuilderSetCurrent('category', categoryIndex);
      this.setState({
        editingCategory: categories[categoryIndex].name,
        categoryDisplay: [categories[categoryIndex].name]
      });
    }
    this.menuBuilderSetCurrent('subCategory', null);
    this.menuBuilderSetCurrent('item', null);
    this.setFormHandlerStep(1);
  };

  menuBuilderSetSubCategory = async newSubCategory => {
    const { categories, current, editingSubCategory } = this.state;
    const currentCategory = categories[current.category].name;
    try {
      if (editingSubCategory) {
        await MenuAPI.updateSubCategory(currentCategory, editingSubCategory, newSubCategory);
        this.alert('success', 'Sub-Category Updated');
        this.setState(
          state => (state.categories[current.category].subCategories[current.subCategory].name = newSubCategory)
        );
        this.setState({
          editingSubCategory: null,
          subCategoryDisplay: [categories[current.category].subCategories[current.subCategory].name]
        });
      } else {
        const subCategoryModel = [{ name: newSubCategory, items: [] }];
        this.setState(
          state => (state.categories[current.category].subCategories = [
              ...state.categories[current.category].subCategories,
              ...subCategoryModel
            ])
        );
      }
      this.setFormHandlerStep(4);
    } catch (error) {
      this.setState({ error: `Update Sub-Category => ${error}` });
    }
  };

  editSubCategory = subCategoryIndex => {
    const { categories, current } = this.state;
    if (!current.subCategory) {
      const subCategory = categories[current.category].subCategories[subCategoryIndex].name;
      this.menuBuilderSetCurrent('subCategory', subCategoryIndex);
      this.setState({
        editingSubCategory: subCategory,
        subCategoryDisplay: [categories[current.category].subCategories[subCategoryIndex].name]
      });
      this.itemDisplay(subCategoryIndex);
    } else {
      const subCategory = categories[current.category].subCategories[current.subCategory].name;
      this.setState({
        editingSubCategory: subCategory,
        subCategoryDisplay: [categories[current.category].subCategories[current.subCategory].name]
      });
      this.itemDisplay(current.subCategory);
    }
    this.menuBuilderSetCurrent('item', null);
    this.setFormHandlerStep(2);
  };

  addSubCategory = () => {
    this.setFormHandlerStep(2);
    this.scrollToFormHandler();
  };

  handleCategoryClick = async categoryIndex => {
    const { categories, sideNavCollapse } = this.state;
    if (sideNavCollapse) {
      this.menuBuilderSetCurrent('category', null);
      this.mapAndSet('categories');
      this.setState(state => ({
        sideNavCollapse: !state.sideNavCollapse,
        addSubCategoryBtn: false
      }));
    } else {
      await this.menuBuilderSetCurrent('category', categoryIndex);
      this.mapAndSet('subCategories', categoryIndex);
      this.setState(state => ({
        sideNavCollapse: !state.sideNavCollapse,
        categoryDisplay: [categories[categoryIndex].name]
      }));
    }
    this.menuBuilderSetCurrent('item', null);
    this.setFormHandlerStep(4);
  };

  handleSubCategoryClick = subCategoryIndex => {
    const { current, categories } = this.state;
    this.setFormHandlerStep(4);
    if (current.subCategory !== null) {
      this.mapAndSet('subCategories');
    } else {
      this.menuBuilderSetCurrent('subCategory', subCategoryIndex);
      this.setState({
        subCategoryDisplay: [categories[current.category].subCategories[subCategoryIndex].name],
        addSubCategoryBtn: false
      });
      this.itemDisplay(subCategoryIndex);
    }
  };

  handleItemClick = async itemIndex => {
    const { categories, current } = this.state;
    const itemId = categories[current.category].subCategories[current.subCategory].items[itemIndex].id;
    this.menuBuilderReset('modifiersLoaded');
    this.menuBuilderSetCurrent('item', itemIndex);
    await this.setFormHandlerStep(4);
    this.setFormHandlerStep(3);
    this.menuBuilderLoadModifiers(itemId);
    this.scrollToFormHandler();
  };

  menuBuilderSetItem = async item => {
    const { current, categories } = this.state;
    const currentCategory = categories[current.category].name;
    const currentSubCategory = categories[current.category].subCategories[current.subCategory].name;
    const itemArrLen = categories[current.category].subCategories[current.subCategory].items.length;

    if (current.item === itemArrLen) {
      // New item
      try {
        await MenuAPI.postItem(currentCategory, currentSubCategory, item);
      } catch (error) {
        const { response } = error;
        if (response && response.status === 409) {
          const { data } = response;
          alert(
            `That Search ID has been found in Item: ${data.name} under Class: ${data.class} ${data.subclass
              && `and Subclass: ${data.subclass}`}\n\n Each item must have a unique Search ID`
          );
          return String(Date.now());
        }
        this.setState({ error: `Post Item => ${error}` });
      }
      await this.setState(state => {
        state.categories[current.category].subCategories[current.subCategory].items = [
          ...state.categories[current.category].subCategories[current.subCategory].items,
          item
        ];
      });
      this.alert('success', 'Item Saved');
    } else {
      // Updating an item
      try {
        await MenuAPI.updateItem(currentCategory, currentSubCategory, item, item.id);
      } catch (error) {
        const { response } = error;
        if (response && response.status === 409) {
          const { data } = response;
          alert(
            `That Search ID has been found in Item: ${data.name} under Class: ${data.class} ${data.subclass
              && `and Subclass: ${data.subclass}`}\n\n Each item must have a unique Search ID`
          );
          return data.previousSearchId;
        }
        this.setState({ error: `Update Item => ${error}` });
      }
      this.setState(state => {
        state.categories[current.category].subCategories[current.subCategory].items[current.item] = item;
      });
      this.alert('success', 'Item Updated');
    }
  };

  // menuBuilderDeleteItem = async itemId => {
  //   this.setState(state => state.categories[state.current.category].subCategories[state.current.subCategory].items.splice(
  //       state.current.item,
  //       1
  //     ));
  //   this.setFormHandlerStep(4);
  //   try {
  //     await MenuAPI.deleteItem(itemId);
  //     this.alert('warning', 'Item Deleted');
  //   } catch (error) {
  //     this.setState({ error: `Delete Item => ${error}` });
  //   }
  // };

  newItem = async () => {
    const { current, categories } = this.state;
    const itemArrLen = categories[current.category].subCategories[current.subCategory].items.length;
    this.menuBuilderSetCurrent('item', itemArrLen);
    await this.setFormHandlerStep(4);
    this.setFormHandlerStep(3);
    this.menuBuilderLoadModifiers(null);
    this.scrollToFormHandler();
  };

  menuBuilderLoadModifiers = async itemId => {
    const { current } = this.state;
    /* Saving current indices, otherwise setState after modifier API call doesn't
    set modifiers correctly if navigating around page quickly */
    const currentCategory = current.category;
    const currentSubCategory = current.subCategory;
    const currentItem = current.item;
    if (itemId === null) {
      this.setState({ modifiersLoaded: true });
    } else {
      // const checkAllItemComments = modifiers => {
      //   if (modifiers[0].modifier.length) {
      //     modifiers[0].modifier.forEach(({ name, cost }) => {
      //       allItemComments.forEach(({ comment_name, cost_price }) => {
      //         if (name !== comment_name) {
      //           cost = cost_price;
      //         }
      //       });
      //     });
      //   }
      //   return modifiers;
      // };

      try {
        const modifiers = await MenuAPI.getModifiers(itemId);
        // const moreModifiers = allItemCommentsLoaded && allItemComments ? await checkAllItemComments(modifiers) : modifiers;
        await this.setState(state => {
          state.categories[currentCategory].subCategories[currentSubCategory].items[
            currentItem
          ].modifiers = modifiers;
        });
        if (currentItem === current.item) {
          this.setState({ modifiersLoaded: true });
        }
      } catch (error) {
        this.setState({ modifiersLoaded: false, error: `Modifiers => ${error}` });
      }
    }
  };

  checkForAuth = () => true;

  checkAuth = async () => {
    await this.setState({ twoFA: false, verified: true });
    this.menuBuilderLogIn(undefined, true);
  };

  scrollToFormHandler = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const formHandler = ReactDOM.findDOMNode(this.formHandlerRef.current);
    formHandler.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  menuBuilderSetCurrent = (field, index) => {
    this.setState(state => {
      state.current[field] = index;
    });
  };

  setFormHandlerStep = step => {
    this.setState({ formStep: step });
  };

  menuBuilderReset = (...stateFields) => {
    stateFields.forEach(stateField => {
      switch (stateField) {
        case 'modifiersLoaded':
          this.setState({ modifiersLoaded: false });
          break;
        default:
          throw new Error('menuBuilderReset method called with incorrect argument');
      }
    });
  };


  alert = (color, message) => {
    this.setState({ alert: { color, message } });
    setTimeout(() => {
      this.setState({ alert: {} });
    }, 3500);
  };

  render() {
    const {
      twoFA,
      logInModal,
      userInfo,
      menuLoaded,
      formStep,
      editingCategory,
      storeName,
      alert,
      error,
      sideNavCollapse,
      categoryDisplay,
      subCategoryDisplay,
      itemDisplay,
      addSubCategoryBtn
    } = this.state;

    return (
      <MDBRow>
        {twoFA && <AuthModal checkAuth={this.checkAuth} />}
        {logInModal && (
          <LogInModal
            error={error}
            closeLoginMenu={() => {
              this.setState({logInModal : false, error: false})
            }}
            userInfo={userInfo}
            menuBuilderLogInToStore={this.menuBuilderLogInToStore}
          />
        )}
        {alert.message && (
          <MDBAlert className="alert px-5 mb-5 mr-3" color={alert.color}>
            {alert.message}
          </MDBAlert>
        )}
        <MDBCol lg="5" className="px-md-0 my-3 my-md-5 sidebar-position">
          <NavHandler
            menuBuilderState={this.state}
            menuBuilderEditCategory={this.menuBuilderEditCategory}
            sideNavCollapse={sideNavCollapse}
            categoryDisplay={categoryDisplay}
            subCategoryDisplay={subCategoryDisplay}
            itemDisplay={itemDisplay}
            addSubCategoryBtn={addSubCategoryBtn}
            displayCategories={this.displayCategories}
            handleCategoryClick={this.handleCategoryClick}
            addSubCategory={this.addSubCategory}
            handleSubCategoryClick={this.handleSubCategoryClick}
            editSubCategory={this.editSubCategory}
            handleItemClick={this.handleItemClick}
            newItem={this.newItem}
            mapAndSet={this.mapAndSet}
          />
        </MDBCol>
        <MDBCol
          ref={this.formHandlerRef}
          lg={formStep && menuLoaded ? '7' : '4'}
          className={
            formStep && menuLoaded
              ? 'my-lg-5 offset-lg-5 formhandler-phone-margin'
              : 'my-lg-5 offset-lg-4 formhandler-phone-margin'
          }
        >
          <FormHandler
            menuBuilderState={this.state}
            menuBuilderLogIn={this.menuBuilderLogIn}
            menuBuilderGetMenu={this.menuBuilderGetMenu}
            menuBuilderSetCategory={this.menuBuilderSetCategory}
            menuBuilderSetSubCategory={this.menuBuilderSetSubCategory}
            menuBuilderSetItem={this.menuBuilderSetItem}
            // menuBuilderDeleteItem={this.menuBuilderDeleteItem}
            setFormHandlerStep={this.setFormHandlerStep}
            menuBuilderSetCurrent={this.menuBuilderSetCurrent}
            editingCategory={editingCategory}
            storeName={storeName}
            menuLoaded={menuLoaded}
          />
        </MDBCol>
      </MDBRow>
    );
  }
}

export default MenuBuilder;
