import React from 'react'
import { MDBBtn, MDBTableBody } from 'mdbreact'






class PostEndpoint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
      if (!this.props.show) {
        return null
      }
        return(
            <MDBTableBody>
                {this.props.postEndpoints.post.map(({ postEndpoint}) => (
                                <tr key={postEndpoint}>
                                  <td style={{ fontSize: 22, color: '#ff5722' }}>POST</td>
                                  <td
                                    style={{ fontSize: 22, fontWeight: 'bold', color: '#2e2e2e' }}
                                    className="d-flex justify-content-between align-items-center"
                                  >
                                    {postEndpoint}
                                    <MDBBtn
                                      floating
                                      color="deep-orange"
                                      style={{ margin: 0, padding: 10, right: 0 }}
                                      onClick={() => {
                                        this.props.showCodeSampleFor(postEndpoint);
                                      }}
                                    >
                                      Code Sample
                                    </MDBBtn>
                                  </td>
                                </tr>
                              ))}
            </MDBTableBody>
            

        )

    }
}

export default PostEndpoint