import React, { Component } from 'react';
import {} from 'mdbreact';

import SideNav from './SideNav';
import ItemSideNav from './ItemSideNav';

class NavHandler extends Component {
  componentDidMount() {
    this.props.mapAndSet('categories');
  }

  componentDidUpdate(prevProps) {
    const prevCategoryLen = prevProps.menuBuilderState.categories.length;
    const currentCategoryLen = this.props.menuBuilderState.categories.length;

    if (prevCategoryLen !== currentCategoryLen) {
      this.props.mapAndSet('categories');
    }
  }

  render() {
    const {
      menuBuilderState,
      addSubCategoryBtn,
      sideNavCollapse,
      categoryDisplay,
      subCategoryDisplay,
      itemDisplay,
      displayCategories,
      handleCategoryClick,
      addSubCategory,
      handleSubCategoryClick,
      menuBuilderEditCategory,
      editSubCategory,
      handleItemClick,
      newItem
    } = this.props;

    return (
      <>
        {menuBuilderState.formStep && menuBuilderState.categories.length ? (
          <SideNav
            addSubCategoryBtn={addSubCategoryBtn}
            displayCategories={displayCategories}
            handleCategoryClick={handleCategoryClick}
            addSubCategory={addSubCategory}
            handleSubCategoryClick={handleSubCategoryClick}
            sideNavCollapse={sideNavCollapse}
            categoryDisplay={categoryDisplay}
            subCategoryDisplay={subCategoryDisplay}
            menuBuilderEditCategory={menuBuilderEditCategory}
            editSubCategory={editSubCategory}
          />
        ) : null}
        {menuBuilderState.current.subCategory !== null && menuBuilderState.editingSubCategory === null ? (
          <ItemSideNav
            categoryDisplay={categoryDisplay}
            subCategoryDisplay={subCategoryDisplay}
            itemDisplay={itemDisplay}
            handleItemClick={handleItemClick}
            newItem={newItem}
          />
        ) : null}
      </>
    );
  }
}

export default NavHandler;
