import React, { Component } from 'react';
import {
  MDBCol,
  MDBBtn,
  MDBBtnGroup,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBInput,
  MDBFormInline
} from 'mdbreact';

// import ModifierModal from './ModifierModal';
import CheckBox from './CheckBox';
import DropDown from './DropDown';

const styles = {
  heading: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  input: {
    fontWeight: '700',
    float: 'left',
    width: '50%'
  },
  btn: {
    fontWeight: '900',
    fontSize: '0.7rem'
  },
  addBtn: {
    fontWeight: '900',
    fontSize: '1rem'
  },
  btnComment: {
    borderRadius: 10
  },
  checkBoxGroup: { fontSize: '1.3rem' },
  commentBtn: { padding: '0.8rem' }
};

export default class MenuItemForm extends Component {
  constructor(props) {
    super(props);
    const { item } = props;
    this.state = {
      advanced: false,
      item,
      name: item.name,
      cost: item.cost,
      price: item.price,
      searchId: item.searchId,
      altSearchId: item.altSearchId,
      addToInventory: item.addToInventory,
      taxOne: item.tax.one,
      taxTwo: item.tax.one,
      taxThree: item.tax.two,
      taxToGo: item.tax.three,
      course: item.course,
      printer: item.printer,
      cookScreen: item.cookScreen,
      expoPrinter: item.expoPrinter,
      labelPrinter: item.labelPrinter,
      optionsHideOnCart: item.options.hideOnCart,
      optionsDisableDiscount: item.options.disableDiscount,
      optionsQtyPrompt: item.options.qtyPrompt,
      optionsCheckId: item.options.checkId,
      optionsServiceItem: item.options.serviceItem,
      belongsToTableService: item.belongsTo.tableService,
      belongsToQuickServe: item.belongsTo.quickServe,
      belongsToPhoneOrder: item.belongsTo.phoneOrder,
      belongsToDriveThru: item.belongsTo.driveThru,
      belongsToOnline: item.belongsTo.online,
      belongsToParty: item.belongsTo.party,
      belongsToBar: item.belongsTo.bar,
      currentComment: '',
      currentCommentIndex: item.comments.length
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      item,
      name,
      price,
      advanced,
      currentComment,
      currentCommentIndex,
      hasCameraPermission,
      scan,
      scanFlash,
      flash,
      focusValue
    } = this.state;
    if (!name || !price) {
      return;
    }
    if (
      JSON.stringify(prevState.item) !== JSON.stringify(item)
      || prevState.advanced !== advanced
      || prevState.currentComment !== currentComment
      || prevState.currentCommentIndex !== currentCommentIndex
      || prevState.hasCameraPermission !== hasCameraPermission
      || prevState.scan !== scan
      || prevState.scanFlash !== scanFlash
      || prevState.flash !== flash
      || prevState.focusValue !== focusValue
    ) {
      return;
    }
    if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      this.setItem();
    }
  }

  changeAdvancedView = e => {
    if (e.target.name === 'basic') {
      this.setState({ advanced: false });
    } else if (e.target.name === 'advanced') {
      this.setState({ advanced: true });
    }
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    const valueUp = value.toUpperCase();
    this.setState({ [name]: valueUp });
  };

  handleCheckBox = checkBoxState => {
    const { name, checked } = checkBoxState;
    this.setState({ [name]: checked });
  };

  handleDropDown = dropDownState => {
    const { name, value } = dropDownState;
    this.setState({ [name]: value });
  };

  setModifier = (modifier, modalIndex) => {
    this.setState(state => (state.item.modifiers[modalIndex] = modifier));
  };

  handleCommentChange = e => {
    const valueUp = e.target.value.toUpperCase();
    this.setState({ currentComment: valueUp });
  };

  setComment = commentIndex => {
    this.setState(state => ({
      currentComment: state.item.comments[commentIndex],
      currentCommentIndex: commentIndex
    }));
  };

  newComment = () => {
    this.setState(state => ({
      currentComment: '',
      currentCommentIndex: state.item.comments.length
    }));
  };

  addComment = async () => {
    const { currentComment, currentCommentIndex, item } = this.state;

    if (currentComment.length < 3) {
      alert('Please write a comment longer than 3 characters');
    } else if (currentCommentIndex === item.comments.length) {
      this.setState(state => state.item.comments.push(currentComment.trim()));
      await this.setItem();
    } else {
      this.setState(state => (state.item.comments[currentCommentIndex] = currentComment.trim()));
      await this.setItem();
    }
  };

  setItem = async () => {
    const {
      item,
      name,
      price,
      cost,
      searchId,
      altSearchId,
      addToInventory,
      taxOne,
      taxTwo,
      taxThree,
      taxToGo,
      course,
      printer,
      cookScreen,
      expoPrinter,
      labelPrinter,
      optionsHideOnCart,
      optionsDisableDiscount,
      optionsQtyPrompt,
      optionsCheckId,
      optionsServiceItem,
      belongsToTableService,
      belongsToQuickServe,
      belongsToPhoneOrder,
      belongsToDriveThru,
      belongsToOnline,
      belongsToParty,
      belongsToBar
    } = this.state;
    const { menuBuilderSetItem } = this.props;

    if (!name) {
      alert('Please enter an item name');
    }

    item.id = item.id || Math.round(Date.now() / 123456);
    item.name = name;
    item.price = parseFloat(price);
    item.cost = parseFloat(cost);
    item.addToInventory = addToInventory;
    item.searchId = searchId ? String(searchId) : String(Date.now());
    item.altSearchId = String(altSearchId) || '';
    item.tax.one = taxOne;
    item.tax.two = taxTwo;
    item.tax.three = taxThree;
    item.tax.toGo = taxToGo;
    item.course = course;
    item.printer = printer;
    item.cookScreen = cookScreen;
    item.expoPrinter = expoPrinter;
    item.labelPrinter = labelPrinter;
    item.options.hideOnCart = optionsHideOnCart;
    item.options.disableDiscount = optionsDisableDiscount;
    item.options.qtyPrompt = optionsQtyPrompt;
    item.options.checkId = optionsCheckId;
    item.options.serviceItem = optionsServiceItem;
    item.belongsTo.tableService = belongsToTableService;
    item.belongsTo.quickServe = belongsToQuickServe;
    item.belongsTo.phoneOrder = belongsToPhoneOrder;
    item.belongsTo.driveThru = belongsToDriveThru;
    item.belongsTo.online = belongsToOnline;
    item.belongsTo.party = belongsToParty;
    item.belongsTo.bar = belongsToBar;

    const previousSearchId = await menuBuilderSetItem(item);
    if (previousSearchId) {
      this.setState({ searchId: previousSearchId });
    }
  };

  hideItem = async () => {
    const { optionsHideOnCart } = this.state;
    await this.setState({ optionsHideOnCart: !optionsHideOnCart });
    this.forceUpdate();
  };

  // deleteItem = () => {
  //   const { name, item } = this.state;
  //   const { menuBuilderDeleteItem } = this.props;
  //   if (window.confirm(`Are you sure you want to delete ${name}?`)) {
  //     if (window.confirm(`This action is non-reversible, are you sure you want to delete ${name}?`)) {
  //       menuBuilderDeleteItem(item.id);
  //     }
  //   }
  // };

  scanBarCode = () => {};

  render() {
    const { setFormHandlerStep, category, subCategory /* modifiersLoaded */ } = this.props;
    const {
      item,
      name,
      price,
      cost,
      addToInventory,
      searchId,
      altSearchId,
      taxOne,
      taxTwo,
      taxThree,
      taxToGo,
      course,
      printer,
      cookScreen,
      expoPrinter,
      labelPrinter,
      optionsHideOnCart,
      optionsDisableDiscount,
      optionsQtyPrompt,
      optionsCheckId,
      optionsServiceItem,
      belongsToTableService,
      belongsToQuickServe,
      belongsToPhoneOrder,
      belongsToDriveThru,
      belongsToOnline,
      belongsToParty,
      belongsToBar,
      currentComment,
      advanced
    } = this.state;

    return (
      <MDBCard>
        <MDBCardBody>
          <MDBCol className="mb-1 d-flex justify-content-center">
            <MDBBtnGroup>
              <MDBBtn
                name="basic"
                outline={advanced}
                color={advanced ? 'blue-grey-text' : 'deep-orange darken-4'}
                className="mr-1"
                onClick={this.changeAdvancedView}
              >
                Basic
              </MDBBtn>
              <MDBBtn
                name="advanced"
                color={!advanced ? 'success' : 'deep-orange darken-4'}
                className="ml-1"
                onClick={this.changeAdvancedView}
              >
                Advanced
              </MDBBtn>
            </MDBBtnGroup>
          </MDBCol>

          {!advanced && (
            <>
              <MDBCol>
                <h4 className="text-center text-info py-4" style={styles.heading}>
                  <span className="text-muted">{category}</span> <MDBIcon icon="angle-right" />{' '}
                  <span className="text-muted">{subCategory}</span> {<br />}
                  Item: {<span className="deep-orange-text">"{name}"</span>}
                  {<br />}
                </h4>
                <MDBInput
                  label="Item"
                  size="lg"
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                />
                <MDBInput
                  label="Search ID"
                  size="lg"
                  type="text"
                  name="searchId"
                  onChange={this.handleChange}
                  value={searchId}
                />
                <MDBInput
                  label="Price"
                  size="lg"
                  type="text"
                  name="price"
                  value={price.toString()}
                  onChange={this.handleChange}
                />
                <MDBInput
                  label="Cost"
                  size="lg"
                  type="text"
                  name="cost"
                  value={cost.toString()}
                  onChange={this.handleChange}
                />
              </MDBCol>
              <MDBCol>
                <CheckBox
                  handleCheckBox={this.handleCheckBox}
                  label="Add to Inventory"
                  name="addToInventory"
                  checked={addToInventory}
                />
                <br />
                <CheckBox
                  handleCheckBox={this.handleCheckBox}
                  label="Check ID"
                  name="optionsCheckId"
                  parent="options"
                  checked={optionsCheckId}
                />
              </MDBCol>
              <MDBCol>
                <br />
                <div>
                  <h4 className="deep-orange-text">
                    Tax <span className="h5 text-info">(Uncheck for NONE)</span>
                  </h4>
                  <hr />
                  <MDBFormInline>
                    <CheckBox
                      handleCheckBox={this.handleCheckBox}
                      parent="tax"
                      label="1"
                      name="taxOne"
                      checked={taxOne}
                    />
                    <CheckBox
                      handleCheckBox={this.handleCheckBox}
                      parent="tax"
                      label="2"
                      name="taxTwo"
                      checked={taxTwo}
                    />
                    <CheckBox
                      handleCheckBox={this.handleCheckBox}
                      parent="tax"
                      label="3"
                      name="taxThree"
                      checked={taxThree}
                    />
                    <CheckBox
                      handleCheckBox={this.handleCheckBox}
                      parent="tax"
                      label="To Go"
                      name="taxToGo"
                      checked={taxToGo}
                    />
                  </MDBFormInline>
                </div>
              </MDBCol>
              <MDBCol>
                <hr />
                <h5 className="text-center text-info pt-4 pb-2">"Advanced" for more options</h5>
              </MDBCol>
              <MDBCol className="mb-xl-0 mb-1 d-flex justify-content-center">
                <MDBBtn
                  name="advanced"
                  color={!advanced ? 'success' : 'deep-orange darken-4'}
                  onClick={this.changeAdvancedView}
                >
                  Advanced
                </MDBBtn>
              </MDBCol>
            </>
          )}

          {advanced && (
            <>
              <MDBCol>
                <h2 className="deep-orange-text text-center pt-4 pb-2">Advanced Options</h2>
                <h4 className="text-center text-info pt-2 pb-4" style={styles.heading}>
                  <span className="text-muted">{category}</span> <MDBIcon icon="angle-right" />{' '}
                  <span className="text-muted">{subCategory}</span> {<br />}
                  Item: {<span className="deep-orange-text">"{name}"</span>}
                  {<br />}
                </h4>
              </MDBCol>
              <MDBCol>
                <MDBInput
                  label="Alt Search ID"
                  size="lg"
                  type="text"
                  name="altSearchId"
                  onChange={this.handleChange}
                  value={altSearchId}
                />
                <DropDown
                  length={8}
                  label="Course"
                  name="course"
                  handleDropDown={this.handleDropDown}
                  value={course}
                />
                <DropDown
                  length={7}
                  label="Printer"
                  name="printer"
                  handleDropDown={this.handleDropDown}
                  value={printer}
                />
                <DropDown
                  length={7}
                  label="Cook Screen"
                  name="cookScreen"
                  handleDropDown={this.handleDropDown}
                  value={cookScreen}
                />
                <DropDown
                  length={7}
                  label="Expo Printer"
                  name="expoPrinter"
                  handleDropDown={this.handleDropDown}
                  value={expoPrinter}
                />
                <DropDown
                  length={4}
                  label="Label Printer"
                  name="labelPrinter"
                  handleDropDown={this.handleDropDown}
                  value={labelPrinter}
                />
                <br />
              </MDBCol>
              <MDBCol>
                <h4 className="deep-orange-text">Options</h4>
                <hr />
                <MDBFormInline>
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="options"
                    label="Hide on cart (disables item)"
                    name="optionsHideOnCart"
                    checked={optionsHideOnCart}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="options"
                    label="Disable discount"
                    name="optionsDisableDiscount"
                    checked={optionsDisableDiscount}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="options"
                    label="Quantity prompt"
                    name="optionsQtyPrompt"
                    checked={optionsQtyPrompt}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="options"
                    label="Service item"
                    name="optionsServiceItem"
                    checked={optionsServiceItem}
                  />
                </MDBFormInline>
              </MDBCol>
              <br />
              <MDBCol>
                <h4 className="deep-orange-text">Belongs to</h4>
                <hr />
                <MDBFormInline>
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Table service"
                    name="belongsToTableService"
                    checked={belongsToTableService}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Quick serve"
                    name="belongsToQuickServe"
                    checked={belongsToQuickServe}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Phone order"
                    name="belongsToPhoneOrder"
                    checked={belongsToPhoneOrder}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Drive thru"
                    name="belongsToDriveThru"
                    checked={belongsToDriveThru}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Online"
                    name="belongsToOnline"
                    checked={belongsToOnline}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Party"
                    name="belongsToParty"
                    checked={belongsToParty}
                  />
                  <CheckBox
                    handleCheckBox={this.handleCheckBox}
                    parent="belongsTo"
                    label="Bar"
                    name="belongsToBar"
                    checked={belongsToBar}
                  />
                </MDBFormInline>
              </MDBCol>
              <br />
              <MDBCol>
                <h4 className="deep-orange-text">Item Comments</h4>
                <hr />
                {item.comments.map((commentName, index) => (
                  <MDBBtn
                    key={index}
                    color="info"
                    style={styles.commentBtn}
                    onClick={() => {
                      this.setComment(index);
                    }}
                  >
                    {commentName || 'NEW'}
                  </MDBBtn>
                ))}
                <MDBBtn color="orange" style={styles.modalBtn} onClick={this.newComment}>
                  <MDBIcon icon="plus" size="lg" inverse="true" style={styles.icon} />
                </MDBBtn>
                <MDBInput
                  type="textarea"
                  label="Comments"
                  rows="1"
                  icon="pencil-alt"
                  onChange={this.handleCommentChange}
                  value={currentComment}
                />
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn color="success" outline style={styles.btnComment} onClick={this.addComment}>
                    Save Comment
                  </MDBBtn>
                </MDBCol>
              </MDBCol>
              <br />
              {/* <MDBCol>
                <h4 className="deep-orange-text">Modifiers</h4>
                <hr />
                {modifiersLoaded ? (
                  <MDBCol className="d-flex justify-content-center">
                    <ModifierModal
                      number={1}
                      currentModifier={item.modifiers[0]}
                      itemName={name}
                      setModifier={this.setModifier}
                    />
                    <ModifierModal
                      number={2}
                      currentModifier={item.modifiers[1]}
                      itemName={name}
                      setModifier={this.setModifier}
                    />
                    <ModifierModal
                      number={3}
                      currentModifier={item.modifiers[2]}
                      itemName={name}
                      setModifier={this.setModifier}
                    />
                    <ModifierModal
                      number={4}
                      currentModifier={item.modifiers[3]}
                      itemName={name}
                      setModifier={this.setModifier}
                    />
                  </MDBCol>
                ) : (
                  <h6 className="text-center text-danger py-4"> Modifiers are loading...</h6>
                )}
              </MDBCol> */}
              <MDBCol>
                <h5 className="text-center text-info py-4">
                  Turn on "Basic" at the top to see basic options
                </h5>
              </MDBCol>
            </>
          )}
          <MDBCol />
          <hr />
          <MDBCol className="d-flex justify-content-center">
            {/* <MDBBtn
              color="info"
              type="submit"
              className="px-4 py-2 mb-3"
              onClick={this.setItem}
              style={styles.addBtn}
            >
              Save Item
              <MDBIcon far icon="thumbs-up" className="ml-2" size="lg" />
            </MDBBtn> */}
          </MDBCol>
          <MDBCol className="d-flex justify-content-center">
            <MDBBtn
              outline
              color="info"
              className="px-4 py-2 ml-1"
              onClick={() => {
                setFormHandlerStep(4);
              }}
              style={styles.btn}
            >
              <MDBIcon icon="arrow-left" className="mr-2" size="lg" /> Back
            </MDBBtn>
            <MDBBtn
              color={optionsHideOnCart ? 'success' : 'danger'}
              // type="submit"
              outline
              className="px-3 py-2 mr-1"
              onClick={this.hideItem}
              style={styles.btn}
            >
              {optionsHideOnCart ? (
                <>
                  Enable Item <MDBIcon icon="plus" className="ml-2" size="lg" />
                </>
              ) : (
                <>
                  Disable item <MDBIcon icon="minus" className="ml-2" size="lg" />
                </>
              )}
            </MDBBtn>
          </MDBCol>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
