import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';

import './App.css';
import Nav from './components/menu/Nav';
import MenuBuilder from './pages/menu/MenuBuilder';
import ListGroupPage from './components/reservations/location';
import ServicePage from './components/reservations/service';
import SchedulePage from './components/reservations/schedule';
import ConfirmationPage from './components/reservations/confirmation';
import DeveloperPage from './pages/developer/DeveloperPage';


const App = () => (
  <Router>
    <>
      <Nav />
      <MDBContainer fluid>
        <Switch>
          <Route exact path="/" component={MenuBuilder} />
          <Route exact path="/list" component={ListGroupPage} />
          <Route exact path="/service" component={ServicePage} />
          <Route exact path="/schedule" component={SchedulePage} />
          <Route exact path="/confirmation" component={ConfirmationPage} />
          <Route exact path="/developer" component={DeveloperPage} />
        </Switch>
      </MDBContainer>
    </>
  </Router>
);

export default App;
