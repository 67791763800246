/* eslint-disable consistent-return */
import React, { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
  MDBIcon,
  MDBAlert,
  MDBCardBody,
  MDBTable,
  MDBTableHead,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBDropdown, 
  MDBDropdownToggle, 
  MDBDropdownMenu, 
  MDBDropdownItem
} from 'mdbreact';

import Prism from 'prismjs';
import '../../assets/css/prism.css';

import DeveloperAPI from '../../utils/developer/DeveloperAPI';
import MenuAPI from '../../utils/menu/MenuAPI';

import codeSamples from './codeSamples';
import GetEndpoint from '../../components/developerPortal/getEndpoint'
import UpdateEndpoint from '../../components/developerPortal/updateEndpoint'
import PostEndpoint from '../../components/developerPortal/postEndpoint'


class MenuBuilder extends Component {
  state = {
    error: null,
    loading: false,
    userInfo: {},
    alert: {},
    loggedIntoStore: '',
    formStep: 0,
    token: '',
    userName: '',
    passWord: '',
    allEndpoints: null,
    tableName: '',
    columnName: '',
    HTTPMethod: '',
    codeModal: false,
    displayEndpoint: '',
    lang: 'JS',
    showUPDATE: false,
    showPOST: false,
    showGET: false,
  };

  async componentDidMount() {
    setTimeout(() => Prism.highlightAll(), 0);
    const allEndpoints = await DeveloperAPI.getAllEndpoints();
    this.setState({ allEndpoints });
  }

  componentDidUpdate() {
    setTimeout(() => Prism.highlightAll(), 0);
    const { error } = this.state;
    if (error) {
      alert(error);
      this.setState({ error: false });
    }
  }

  handleInputChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleDropDownChange = ({ target }) => {
    const { value } = target;
    this.setState({ HTTPMethod: value });
  };

  handleClickEnpoint = (e) => {
    console.log("im working")
    console.log(e.target.innerText)
    let button = e.target.innerText;
    if (button === "UPDATE") {
      console.log("UPDATING...")
      this.setState({
        showUPDATE: true,
        showPOST: false,
        showGET: false
      })
    }
     else if(button === "GET") {
       console.log("GETTING...")
      this.setState({
        showUPDATE: false,
        showPOST: false,
        showGET: true
      })

    } else if(button === "POST") {
      console.log("POSTING...")
      this.setState({
        showUPDATE: false,
        showPOST: true,
        showGET: false
      })
    } else {
      console.log("ALLING...")
      this.setState({
        showUPDATE: true,
        showPOST: true,
        showGET: true
      })
    }
    console.log(this.state);



  }

  submitEndpoint = e => {
    e.preventDefault();
    const { tableName, columnName, HTTPMethod } = this.state;
    if (!tableName) {
      return alert('Please enter a valid Table Name');
    }
    if (!columnName) {
      return alert('Please enter a valid Column Name');
    }
    if (!HTTPMethod || HTTPMethod === 'Choose HTTP Method') {
      return alert('Please enter a valid HTTP Method');
    }
  };

  alert = (color, message) => {
    this.setState({ alert: { color, message } });
    setTimeout(() => {
      this.setState({ alert: {} });
    }, 3500);
  };

  developerPageLogIn = async e => {
    e.preventDefault();
    const { userName, passWord } = this.state;
    if (userName && passWord) {
      this.setState({ loading: true });
      const logInInfo = {};
      logInInfo.userName = userName;
      logInInfo.passWord = passWord;
      try {
        this.setState({ logInModal: true });
        const { storeNames, clientId } = await MenuAPI.logIn(logInInfo);
        if (storeNames) {
          await this.setState({
            userInfo: {
              clientId,
              storeNames
            }
          });
          this.setState({ formStep: 1, userName: '', passWord: '', loading: false });
        } else {
          this.setState({ error: 'LogIn no Store Names', loading: false });
        }
      } catch (error) {
        this.setState({ error: `LogIn => ${error}`, loading: false });
      }
    } else {
      this.setState({ error: 'No username and password provided' });
    }
  };

  developerPageLogInToStore = async loggingIntoStoreName => {
    const { userInfo } = this.state;
    const { clientId } = userInfo;
    const logInInfo = {};
    logInInfo.clientId = clientId;
    logInInfo.storeName = loggingIntoStoreName;
    this.setState({ loading: true });
    try {
      const { storeName, token } = await MenuAPI.logInToStoreAndReturnToken(logInInfo);
      this.setState({ loggedIntoStore: storeName, token, formStep: 2, loading: false });
    } catch (error) {
      this.setState({ error: `LogIn To Store => ${error}`, loading: false });
    }
  };

  showCodeSampleFor = endpoint => {
    this.setState({ codeModal: true, displayEndpoint: endpoint });
  };

  closeCodeModal = () => {
    this.setState({ codeModal: false });
  };

  copyTokenToClipboard = () => {
    const { token } = this.state;
    navigator.clipboard.writeText(token);
    this.alert('success', 'Token Copied to Clipboard');
  };

  copyCodeToClipboard = () => {
    const { token, displayEndpoint, lang } = this.state;
    const lowerCaseLang = lang.toLowerCase();
    const codeSample = codeSamples(token);
    navigator.clipboard.writeText(codeSample[displayEndpoint].languages[lowerCaseLang]);
    this.alert('success', `${lang} Code Copied to Clipboard`);
  };

  displaySample = () => {
    const { token, displayEndpoint, lang } = this.state;
    const lowerCaseLang = lang.toLowerCase();
    const codeSample = codeSamples(token);
    return codeSample[displayEndpoint].languages[lowerCaseLang];
  };

  displayDescription = () => {
    const { displayEndpoint } = this.state;
    const codeSample = codeSamples('');
    return codeSample[displayEndpoint].description;
  };

  render() {
    const {
      loading,
      alert,
      formStep,
      userInfo,
      token,
      loggedIntoStore,
      userName,
      passWord,
      allEndpoints,
      tableName,
      columnName,
      HTTPMethod,
      codeModal,
      displayEndpoint,
      lang
    } = this.state;

    switch (formStep) {
      case 0:
        return (
          <MDBRow className="d-flex justify-content-center my-4">
            <MDBCard>
              <MDBCardBody>
                <MDBCol>
                  <h2 className="text-center pt-4 pb-2 deep-orange-text">
                    Welcome to the AST API Developer Portal
                  </h2>
                </MDBCol>
                <hr style={{ backgroundColor: '#ff5722', borderRadius: 25 }} className="mx-5" />
                <MDBCol>
                  <form>
                    <h2 className="text-center pt-2 pb-4 text-info">Login With Aura Credentials</h2>
                    <MDBInput
                      label="Aura Username"
                      size="lg"
                      icon="user"
                      type="text"
                      name="userName"
                      className="mb-5"
                      onChange={this.handleInputChange}
                      value={userName}
                    />
                    <MDBInput
                      label="Aura Password"
                      size="lg"
                      icon="key"
                      type="password"
                      name="passWord"
                      onChange={this.handleInputChange}
                      value={passWord}
                    />
                    <MDBCol className="d-flex justify-content-center mt-5">
                      <MDBBtn
                        style={{ fontSize: 22 }}
                        color="success"
                        type="submit"
                        onClick={this.developerPageLogIn}
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          'Login'
                        )}
                      </MDBBtn>
                    </MDBCol>
                  </form>
                </MDBCol>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        );
      case 1: {
        const { storeNames } = userInfo;
        return (
          <MDBRow className="d-flex justify-content-center my-4">
            <MDBCard>
              <MDBCardBody>
                <MDBCol>
                  <h2 className="text-center pt-4 pb-2 deep-orange-text">
                    Welcome to the AST API Developer Portal
                  </h2>
                </MDBCol>
                <hr style={{ backgroundColor: '#ff5722', borderRadius: 25 }} className="mx-5" />
                <MDBCol>
                  <MDBCol className="d-flex justify-content-center">
                    {loading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <h2 className="text-center pt-2 pb-4 text-dark">Choose a Store</h2>
                    )}
                  </MDBCol>
                  {storeNames.map(storeName => (
                    <MDBBtn
                      key={storeName}
                      color="info"
                      style={{ fontSize: 22 }}
                      className="px-2 py-2 my-2"
                      disabled={loading}
                      onClick={() => {
                        this.developerPageLogInToStore(storeName);
                      }}
                    >
                      {storeName}
                    </MDBBtn>
                  ))}
                </MDBCol>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        );
      }
      case 2:
        return (
          <>
            {alert.message && (
              <MDBAlert className="alert px-5 mb-5 mr-3" color={alert.color}>
                {alert.message}
              </MDBAlert>
            )}
            <MDBModal size="lg" isOpen={codeModal} toggle={this.closeCodeModal}>
              <MDBModalBody>
                <h2 className="text-center my-4">
                  Endpoint
                  <br />"<span className="deep-orange-text">{displayEndpoint}</span>"
                </h2>
                <MDBCol className="text-center mb-4">
                  <h2>
                    Description
                    <br />
                    <span className="h3 text-info">{codeModal && this.displayDescription()}</span>
                  </h2>
                </MDBCol>
                <MDBCol>
                  <figure>
                    <MDBBtn
                      style={{ padding: 8, position: 'absolute', right: -5, top: 20 }}
                      color="info"
                      floating
                      onClick={this.copyCodeToClipboard}
                    >
                      <MDBIcon icon="copy" size="lg" />
                    </MDBBtn>
                    <MDBBtnGroup>
                      {['JS', 'Java', 'PHP', 'cURL'].map(selectedLang => (
                        <MDBBtn
                          key={selectedLang}
                          color={selectedLang === lang ? 'elegant' : null}
                          size="lg"
                          onClick={() => {
                            this.setState({ lang: selectedLang });
                          }}
                        >
                          {selectedLang}
                        </MDBBtn>
                      ))}
                    </MDBBtnGroup>
                    <pre style={{ padding: 5, marginTop: 2 }} className="line-numbers">
                      <code className="language-js">{codeModal && this.displaySample()}</code>
                    </pre>
                  </figure>
                </MDBCol>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="elegant" className="rounded-pill" onClick={this.closeCodeModal}>
                  close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBRow className="d-flex justify-content-center my-4">
              <MDBCol xl="8">
                <MDBCard>
                  <MDBCardBody>
                    <MDBCol>
                      <h2 className="text-center pt-4 pb-2 deep-orange-text">
                        Welcome to the AST API Developer Portal
                      </h2>
                      <h3 className="text-center pt-2 pb-4 text-dark">
                        Please review{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/document/d/1T4xma7HAnYpSfu4NM1jqhvEdifSmXtiPAAAkYohit80/edit?usp=sharing"
                        >
                          this
                        </a>{' '}
                        Google Drive for API documentation
                      </h3>
                    </MDBCol>
                    <hr />
                    <MDBCol>
                      <MDBCol>
                        <h3 className="text-center pb-4 text-muted">
                          <span className="text-info">
                            Generated Token For <span className="deep-orange-text">{loggedIntoStore}</span>:{' '}
                          </span>
                          primaryKey      <div
                            style={{
                              backgroundColor: '#D3D3D3',
                              fontSize: 16,
                              borderStyle: 'dashed',
                              borderColor: 'black',
                              borderWidth: 1,
                              borderRadius: 5,
                              marginTop: 5,
                              padding: 5,
                              textAlign: 'left'
                            }}
                          >
                            {document.queryCommandSupported('copy') && (
                              <MDBBtn
                                style={{ padding: 8, position: 'absolute', right: -10, top: 0 }}
                                color="info"
                                floating
                                onClick={this.copyTokenToClipboard}
                              >
                                <MDBIcon icon="copy" size="lg" />
                              </MDBBtn>
                            )}
                            <span ref={tokenCopy => (this.tokenCopy = tokenCopy)}>{token} </span>
                          </div>
                        </h3>
                      </MDBCol>
                      {allEndpoints && (
                        <MDBCol>
                          <hr />
                          <h2 className="text-center py-4 deep-orange-text">Existing CRUD Endpoints</h2>
                          <MDBTable striped bordered responsive>
                            <MDBTableHead className="dropdown" color="info-color" textWhite>
                              <tr>
                                <th>Endpoint</th>
                                <th>
                                  <MDBBtn color="elegant" onClick={this.handleClickEnpoint}>GET</MDBBtn>
                                  <MDBBtn color="elegant" onClick={this.handleClickEnpoint}>POST</MDBBtn>
                                  <MDBBtn color="elegant" onClick={this.handleClickEnpoint}>UPDATE</MDBBtn>
                                  <MDBBtn color="elegant" onClick={this.handleClickEnpoint} >All</MDBBtn>
                                </th>
                              </tr>
                            </MDBTableHead>
                                <GetEndpoint getEndpoints={allEndpoints} show={this.state.showGET} showCodeSampleFor={this.showCodeSampleFor} />
                                <UpdateEndpoint updateEndpoints={allEndpoints} show={this.state.showUPDATE} showCodeSampleFor={this.showCodeSampleFor}/>
                                <PostEndpoint postEndpoints={allEndpoints} show={this.state.showPOST} showCodeSampleFor={this.showCodeSampleFor}/>
                          </MDBTable>
                        </MDBCol>
                      )}
                      <MDBCol>
                        <h2 className="text-center pt-2 pb-4 deep-orange-text">
                          Create Endpoint <span className="h5 text-muted">(Coming Soon)</span>
                        </h2>
                        <h3 className="text-center pb-4 text-muted">
                          <span className="align-self-start text-info">Generated endpoint: </span>
                          ...Coming Soon
                        </h3>
                        <div>
                          <h3 className="text-center pb-4 text-info">HTTP Method</h3>
                          <div className="mb-2">
                            <select
                              className="browser-default custom-select"
                              value={HTTPMethod}
                              onChange={this.handleDropDownChange}
                              disabled
                            >
                              {['Choose HTTP Method', 'GET', 'UPDATE', 'POST'].map(HTTPMethodChoice => (
                                <option
                                  key={HTTPMethodChoice}
                                  name={HTTPMethodChoice}
                                  value={HTTPMethodChoice}
                                >
                                  {HTTPMethodChoice}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <MDBInput
                          label="Table Name"
                          size="lg"
                          type="text"
                          name="tableName"
                          onChange={this.handleInputChange}
                          value={tableName}
                          disabled
                        />
                        <MDBInput
                          label="Column Name"
                          size="lg"
                          type="text"
                          name="columnName"
                          onChange={this.handleInputChange}
                          value={columnName}
                          disabled
                        />
                      </MDBCol>
                      <MDBCol className="d-flex justify-content-center">
                        <MDBBtn
                          style={{ fontSize: 22 }}
                          color="success"
                          type="submit"
                          floating
                          onClick={this.submitEndpoint}
                          disabled
                        >
                          Generate Endpoint
                        </MDBBtn>
                      </MDBCol>
                    </MDBCol>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </>
        );
      default:
        throw new Error(`Not a valid form step: ${formStep}`);
    }
  }
}

export default MenuBuilder;
