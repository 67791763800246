import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { BrowserRouter as Link } from "react-router-dom";
class ConfirmationPage extends Component {
	render() {
		return(
			<Container>
			<h1 style={{ marginBottom: '2rem', marginTop: '2rem' }}className="text-center">Confirmation</h1>
			<hr />
				<Table responsive>
					<thead>
					<tr>
						<th>Review Order</th>	
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>Location: </td>
						<td>5990 Stonridge Dr Suite 101, Pleasanton, CA 94588</td>
					</tr>
					<tr>
					<td>Service: </td>
						<td>Haircut</td>
					</tr>
					<tr>
						<td>Appointment Time: </td>
						<td>4:30pm</td>
					</tr>
					<tr>
						<td>Paid?</td>
						<td>Yes</td>
					</tr>
					<tr>
						<td>Price: </td>
						<td>$20:00 </td>
					</tr>
					</tbody>
				</Table>
				<Link to='/'>
					<Button variant="primary">Confirm</Button>
				</Link>
			</Container>
		);
	}
}

export default ConfirmationPage;