import React from 'react';
import {
 MDBBtn, MDBIcon, MDBListGroup, MDBListGroupItem, MDBCollapse
} from 'mdbreact';

const styles = {
  itemList: {
    cursor: 'pointer'
  }
};

const ItemSideNav = props => {
  const {
 categoryDisplay, subCategoryDisplay, itemDisplay, newItem, handleItemClick
} = props;
  return (
    <MDBCollapse isOpen>
      <MDBListGroupItem color="warning" className="h5 py-1 ml-4 mr-2">
        <small>
          {categoryDisplay} <MDBIcon icon="angle-right" /> {subCategoryDisplay}{' '}
        </small>
        <MDBIcon icon="angle-right" />
        <span> ITEMS </span>{' '}
        <small>
          <MDBIcon icon="level-down-alt" />
        </small>{' '}
      </MDBListGroupItem>
      <MDBListGroup className="ml-4 mr-2 item-sidenav-height">
        {itemDisplay.map((name, itemIndex) => (
          <MDBListGroupItem
            hover
            className="text-dark winter-neva-gradient"
            key={itemIndex}
            style={styles.itemList}
            onClick={() => {
              handleItemClick(itemIndex);
            }}
          >
            {name}
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
      <MDBBtn color="deep-orange darken-4" className="my-0 ml-4 add-item-width" onClick={newItem}>
        <MDBIcon icon="plus" size="lg" inverse="true" className="mr-1" />
        Add New Item
      </MDBBtn>
    </MDBCollapse>
  );
};

export default ItemSideNav;
