import React from "react";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
import AccordionPage from './accordion_component';

class ServiceModalPage extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			lgShow: false,
		};
	}
	render() {
		let lgClose = () => this.setState({ lgShow: false });

		const serviceItems = [{
			id: 1,
			title: "service item #1",
		}];
		return(
			<Container>
				<Button onClick={() => this.setState({ lgShow: true })}>Select </Button>
				{serviceItems.map((serviceItem, index) => (
					<Modal key={index} size="lg" show={this.state.lgShow} onHide={lgClose} aria-labelledby="example modal-sizes-title-lg">
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-lg"> {serviceItem.title} </Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AccordionPage />
						</Modal.Body>
						<Modal.Footer>
							<Button>Add to Cart</Button>
						</Modal.Footer>
					</Modal>
				))}

			</Container>


		);
	}

}

export default ServiceModalPage;