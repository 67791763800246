import React, { Component } from 'react';
import {
 MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBInput
} from 'mdbreact';

const styles = {
  heading: {
    fontWeight: '500'
  },
  input: {
    fontWeight: '900'
  },
  btnAdd: {
    fontWeight: '900',
    fontSize: '1rem'
  },
  btnBack: {
    fontWeight: '900',
    fontSize: '1rem'
  },
  btnSubmit: {
    fontWeight: '900',
    fontSize: '1rem'
  }
};

class MenuSubCategoryForm extends Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { subCategory: this.props.editingSubCategory || '', edited: false };

  handleChange = ({ target }) => {
    const valueUp = target.value.toUpperCase();
    this.setState({ subCategory: valueUp });
  };

  setSubCategory = e => {
    e.preventDefault();
    const { menuBuilderSetSubCategory } = this.props;
    const { subCategory } = this.state;
    const newSubCategory = subCategory.trim().toUpperCase();
    menuBuilderSetSubCategory(newSubCategory);
  };

  render() {
    const { editingSubCategory, category } = this.props;

    return (
      <MDBCard style={styles.card}>
        <MDBCardBody>
          <form>
            <h2 className="text-center text-info py-4" style={styles.heading}>
              {editingSubCategory ? 'Edit Sub-Category For:' : 'Add Sub-Categories For:'}
              {<br />}
              {<span className="deep-orange-text">"{category}"</span>}
            </h2>
            <MDBCol>
              <MDBInput
                label="Sub-Category"
                size="lg"
                group
                type="text"
                name="subCategory"
                value={this.state.subCategory}
                onChange={this.handleChange}
                className="form-control"
                style={styles.input}
              />
              <MDBCol className="d-flex justify-content-center">
                <MDBBtn
                  outline
                  color="success"
                  type="submit"
                  onClick={this.setSubCategory}
                  style={styles.btnAdd}
                  size="sm"
                >
                  {editingSubCategory ? 'Edit' : 'Add'}
                  <MDBIcon far icon="thumbs-up" className="ml-2" size="lg" />
                </MDBBtn>
              </MDBCol>
            </MDBCol>
          </form>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default MenuSubCategoryForm;
