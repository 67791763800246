import React from 'react';
import {
 MDBIcon, MDBBtn, MDBCollapse, MDBListGroup, MDBListGroupItem
} from 'mdbreact';

const styles = {
  pointer: { cursor: 'pointer' }
};

const colorChange = (type, typeIndex) => {
  const colorIndex = typeIndex % 3;
  const catListItem = {
    borderLeft: '',
    borderRadius: 10
  };
  const subCatListItem = {
    borderLeft: '',
    borderRadius: 10
  };

  if (colorIndex === 1) {
    catListItem.borderLeft = '0.6rem solid #00695c';
    subCatListItem.borderLeft = '0.6rem solid #2BBBAD';
  } else if (colorIndex === 2) {
    catListItem.borderLeft = '0.6rem solid #0d47a1';
    subCatListItem.borderLeft = '0.6rem solid #4285F4';
  } else {
    catListItem.borderLeft = '0.6rem solid #9933CC';
    subCatListItem.borderLeft = '0.6rem solid #aa66cc';
  }
  if (type === 'category') {
    return catListItem;
  }
  if (type === 'subCategory') {
    return subCatListItem;
  }
};

const SideNav = props => {
  const {
    addSubCategoryBtn,
    sideNavCollapse,
    displayCategories,
    categoryDisplay,
    subCategoryDisplay,
    addSubCategory,
    handleCategoryClick,
    handleSubCategoryClick,
    menuBuilderEditCategory,
    editSubCategory
  } = props;
  return (
    <MDBListGroup>
      <MDBListGroupItem className="pr-0 bg-info d-flex flex-nowrap justify-content-between">
        <span className="text-white order-1" style={styles.pointer} onClick={displayCategories}>
          Main Menu
        </span>
        <MDBIcon
          icon="plus"
          size="sm"
          inverse="true"
          style={styles.pointer}
          className="order-2 my-auto mr-4"
          onClick={() => {
            displayCategories(true);
          }}
        />
      </MDBListGroupItem>
      <div className="sidenav-height">
        {categoryDisplay.map((category, categoryIndex) => {
          const catListItem = colorChange('category', categoryIndex);
          return (
            <div key={categoryIndex}>
              <MDBListGroupItem
                className="heavy-rain-gradient py-0 mb-1 d-flex flex-nowrap"
                style={catListItem}
              >
                <div
                  className="text-dark py-2 order-1 flex-grow-1"
                  style={styles.pointer}
                  role="button"
                  onClick={() => {
                    handleCategoryClick(categoryIndex);
                  }}
                >
                  {category}
                </div>

                <MDBIcon
                  icon="edit"
                  size="sm"
                  className="mr-2 my-auto font-weight-light hoverable order-2"
                  style={styles.pointer}
                  onClick={() => {
                    menuBuilderEditCategory(categoryIndex);
                  }}
                />
              </MDBListGroupItem>
              <MDBCollapse isOpen={sideNavCollapse}>
                <MDBListGroup>
                  {subCategoryDisplay.map((subCategory, subCategoryIndex) => {
                    const subCatListItem = colorChange('subCategory', subCategoryIndex);
                    return (
                      <MDBListGroupItem
                        key={subCategoryIndex}
                        className="winter-neva-gradient ml-4 mr-2 d-flex flex-nowrap"
                        style={subCatListItem}
                      >
                        <div
                          className="text-dark order-1 flex-grow-1"
                          role="button"
                          style={styles.pointer}
                          onClick={() => {
                            handleSubCategoryClick(subCategoryIndex);
                          }}
                        >
                          {subCategory}
                        </div>
                        <MDBIcon
                          icon="edit"
                          size="sm"
                          style={styles.pointer}
                          className="hoverable font-weight-light mr-2 my-auto order-2"
                          onClick={() => {
                            editSubCategory(subCategoryIndex);
                          }}
                        />
                      </MDBListGroupItem>
                    );
                  })}
                  {addSubCategoryBtn ? (
                    <MDBBtn color="deep-orange darken-4" className="ml-4 mr-2 my-0" onClick={addSubCategory}>
                      <MDBIcon icon="plus" size="lg" inverse="true" className="mr-1" />
                      Add New Sub-Category
                    </MDBBtn>
                  ) : null}
                </MDBListGroup>
              </MDBCollapse>
            </div>
          );
        })}
      </div>
    </MDBListGroup>
  );
};

export default SideNav;
