import axios from 'axios';

// const axiosConfig = {
//   headers: {
//     Authorization: null
//   },
//   'Access-Control-Allow-Origin': '*',
//   'Access-Control-Allow-Methods': 'DELETE, GET, OPTIONS, PATCH, POST, PUT',
//   'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
// };

export default {
  async getAllEndpoints() {
    const { data } = await axios.get('/api/developer/endpoints/');
    return data;
  }
};
