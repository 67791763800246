export default token => ({
  'https://astapi.com/api/menu/get/items': {
    description: 'Gets all items from database',
    languages: {
      js: `const request = require("request");

    const options = { method: 'GET',
      url: 'https://astapi.com/api/menu/get/items',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         Authorization: '${token}' } };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/get/items")
      .get()
      .addHeader("Authorization", "${token}")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/get/items');
    $request->setMethod(HTTP_METH_GET);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Authorization' => '${token}'
    ));

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X GET \
    https://astapi.com/api/menu/get/items \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache'`
    }
  },
  'https://astapi.com/api/menu/get/business-type': {
    description: 'Gets the business type listed in the system settings table',
    languages: {
      js: `const request = require("request");

    const options = { method: 'GET',
      url: 'https://astapi.com/api/menu/get/business-type',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         Authorization: '${token}' } };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/get/business-type")
      .get()
      .addHeader("Authorization", "${token}")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/get/business-type');
    $request->setMethod(HTTP_METH_GET);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Authorization' => '${token}'
    ));

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X GET \
    https://astapi.com/api/menu/get/business-type \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache'`
    }
  },
  'https://astapi.com/api/menu/get/modifiers/:itemId': {
    description: 'Gets all values related to the modifiers except for "tbl_all_item_comments"',
    languages: {
      js: `const request = require("request");

    const options = { method: 'GET',
      url: 'https://astapi.com/api/menu/get/modifiers/:itemId',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         Authorization: '${token}' } };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/get/modifiers/:itemId")
      .get()
      .addHeader("Authorization", "${token}")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/get/modifiers/:itemId');
    $request->setMethod(HTTP_METH_GET);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Authorization' => '${token}'
    ));

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X GET \
    https://astapi.com/api/menu/get/modifiers/:itemId \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache'`
    }
  },
  'https://astapi.com/api/menu/get/all-item-comments': {
    description: 'Gets all of "tbl_all_item_comments"',
    languages: {
      js: `const request = require("request");

    const options = { method: 'GET',
      url: 'https://astapi.com/api/menu/get/all-item-comments',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         Authorization: '${token}' } };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/get/all-item-comments")
      .get()
      .addHeader("Authorization", "${token}")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/get/all-item-comments');
    $request->setMethod(HTTP_METH_GET);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Authorization' => '${token}'
    ));

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }`,
      curl: `curl -X GET \
    https://astapi.com/api/menu/get/all-item-comments \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache'`
    }
  },
  'https://astapi.com/api/menu/get/item-from-barcode/:barcode': {
    description:
      "Gets item id, name, class, subclass, stock_alert_threshold, and if exists usage_unit_multiplier and stock_entry from an item's barcode",
    languages: {
      js: `const request = require("request");

    const options = { method: 'GET',
      url: 'https://astapi.com/api/menu/get/item-from-barcode/:barcode',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         Authorization: '${token}' } };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/get/item-from-barcode/:barcode")
      .get()
      .addHeader("Authorization", "${token}")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/get/item-from-barcode/:barcode');
    $request->setMethod(HTTP_METH_GET);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Authorization' => '${token}'
    ));

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X GET \
    https://astapi.com/api/menu/get/item-from-barcode/:barcode \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache'`
    }
  },
  'https://astapi.com/api/menu/update/log-in-time': {
    description:
      'Does not take a body, updates lastlogin to this moment in UTC and sets lastlogin2 = (previous) lastlogin and lastlogin3 = (previous) lastlogin2 ',
    languages: {
      js: `const request = require("request");

    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/log-in-time',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body: { clientId: {clientId} },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "clientId": {clientId}
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/log-in-time")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/log-in-time');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
      "clientId": {clientId}
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/log-in-time \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache' \
    -d '{
    "clientId": {clientId}
  }'`
    }
  },
  'https://astapi.com/api/menu/update/location': {
    description: 'Updates the last logged in location (format: "{latitude}:{longitude}")',
    languages: {
      js: `const request = require("request");

    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/location',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body: { locationInfo: { newLocation: '{latitude}:{longitude}', clientId: {clientId} } },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });`,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "locationInfo": {
        "newLocation": "{latitude}:{longitude}",
        "clientId": {clientId}
      }
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/location")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/location');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
      "locationInfo": {
        "newLocation": "{latitude}:{longitude}",
        "clientId": {clientId}
      }
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/location \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache' \
    -d '{
    "locationInfo": {
      "newLocation": "{latitude}:{longitude}",
      "clientId": {clientId}
    }
  }'`
    }
  },
  'https://astapi.com/api/menu/update/inventory-item/:itemId': {
    description:
      'Updates the item in tbl_items as well as the usage_unit_multiplier in tbl_add_ingredients and stock_entry in stock_table',
    languages: {
      js: `const request = require("request");

    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/inventory-item/:itemId',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body: { class: '{class}', usageUnitMultiplier: {usageUnitMultiplier}, stockEntry: {stockEntry} },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "class": "{class}",
      "usageUnitMultiplier": {usageUnitMultiplier},
      "stockEntry": {stockEntry}
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/inventory-item/:itemId")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/inventory-item/12669955');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
        "class": "{class}",
        "usageUnitMultiplier": {usageUnitMultiplier},
        "stockEntry": {stockEntry}
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/inventory-item/:itemId \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache' \
    -d '{
      "class": "{class}",
      "usageUnitMultiplier": {usageUnitMultiplier},
      "stockEntry": {stockEntry}
  }'`
    }
  },
  'https://astapi.com/api/menu/update/item/:itemId': {
    description:
      'Updates item (present fields below are required, any additional are optional and correspond to column names in tbl_items)',
    languages: {
      js: `const request = require("request");
    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/item/:itemId',
      headers:
       { 'cache-control': 'no-cache',
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',
         'Access-Control-Allow-Methods': 'DELETE, GET, OPTIONS, PATCH, POST, PUT',
         'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
         'Authorization': '${token}',
      body: { 'searchid': '{searchId}', 'class': '{class}' },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    })`,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "searchid": "{searchId}",
      "class": "{class}"
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/item/:itemId")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization")
      .addHeader("Access-Control-Allow-Methods", "DELETE, GET, OPTIONS, PATCH, POST, PUT")
      .addHeader("Access-Control-Allow-Origin", "*")
      .addHeader("Content-Type", "application/json")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/item/:itemId');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Content-Type' => 'application/json',
      'Access-Control-Allow-Origin' => '*',
      'Access-Control-Allow-Methods' => 'DELETE, GET, OPTIONS, PATCH, POST, PUT',
      'Access-Control-Allow-Headers' => 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
        "searchid": "{searchId}",
        "class": "{class}"
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/item/:itemId \
    -H 'Access-Control-Allow-Headers: Origin, X-Requested-With, Content-Type, Accept, Authorization' \
    -H 'Access-Control-Allow-Methods: DELETE, GET, OPTIONS, PATCH, POST, PUT' \
    -H 'Access-Control-Allow-Origin: *' \
    -H 'Authorization: ${token}' \
    -H 'Content-Type: application/json' \
    -H 'cache-control: no-cache' \
    -d '{
      "searchid": "{serachId}",
      "class": "{class}"
  }'`
    }
  },
  'https://astapi.com/api/menu/update/category': {
    description: 'Updates every item with certain class (category)',
    languages: {
      js: `const request = require("request");

    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/category',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body: { newCategory: '{newCategory', oldCategory: '{oldCategory}' },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });`,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "newCategory": "{newCategory}",
      "oldCategory": "{oldCategory}"
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/category")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/category');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
        "newCategory": "{newCategory}",
        "oldCategory": "{oldCategory}"
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/category \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache' \
    -d '{
      "newCategory": "{newCategory}",
      "oldCategory": "{oldCategory}"
  }'`
    }
  },
  'https://astapi.com/api/menu/update/sub-category': {
    description: 'Updates every item with certain subclass (sub category)',
    languages: {
      js: `const request = require("request");

    const options = { method: 'PUT',
      url: 'https://astapi.com/api/menu/update/sub-category',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body:
       { currentCategory: '{currentCategory}',
         oldSubCategory: '{oldSubCategory}',
         newSubCategory: '{newSubCategory}' },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "currentCategory": "{currentCategory}",
      "oldSubCategory": "{oldSubCategory}",
      "newSubCategory": "{newSubCategory}"
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/update/sub-category")
      .put(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/update/sub-category');
    $request->setMethod(HTTP_METH_PUT);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
        "currentCategory": "{currentCategory}",
        "oldSubCategory": "{oldSubCategory}",
        "newSubCategory": "{newSubCategory}"
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X PUT \
    https://astapi.com/api/menu/update/sub-category \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Length: 113' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'User-Agent: PostmanRuntime/7.15.2' \
    -H 'cache-control: no-cache' \
    -d '{
      "currentCategory": "APP TEST 2",
      "oldSubCategory": "APP SUB TEST2",
      "newSubCategory": "APP SUB 3"
  }'`
    }
  },
  'https://astapi.com/api/menu/post/item': {
    description:
      'Posts a new item (fields below are required, any additional are optional and correspond to column names in tbl_items)',
    languages: {
      js: `const request = require("request");

    const options = { method: 'POST',
      url: 'https://astapi.com/api/menu/post/item',
      headers:
       { 'cache-control': 'no-cache',
         Connection: 'keep-alive',
         'Accept-Encoding': 'gzip, deflate',
         Host: 'astapi.com',
         'Cache-Control': 'no-cache',
         Accept: '*/*',
         'Content-Type': 'application/json',
         Authorization: '${token}' },
      body:
       { class: '{class}',
         name: '{name}',
         break_price: {breakPrice},
         lunch_price: {lunchPrice},
         dinner_price: {dinnerPrice},
         special_price: {specialPrice},
         happy_hour: {happyHour},
         midnight_price: {midnightPrice},
         searchid: '{searchId}' },
      json: true };

    request(options, (error, response, body) => {
      if (error) throw new Error(error);

      console.log(body);
    });
    `,
      java: `OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(mediaType, "{
      "class": "{class}",
      "name": "{name}",
      "break_price": {breakPrice},
      "lunch_price": {lunchPrice},
      "dinner_price": {dinnerPrice},
      "special_price": {specialPrice},
      "happy_hour": {happyHour},
      "midnight_price": {midnightPrice},
      "searchid": "{searchId}"
    }");
    Request request = new Request.Builder()
      .url("https://astapi.com/api/menu/post/item")
      .post(body)
      .addHeader("Authorization", "${token}")
      .addHeader("Content-Type", "application/json")
      .addHeader("Accept", "*/*")
      .addHeader("Cache-Control", "no-cache")
      .addHeader("Host", "astapi.com")
      .addHeader("Accept-Encoding", "gzip, deflate")
      .addHeader("Connection", "keep-alive")
      .addHeader("cache-control", "no-cache")
      .build();

    Response response = client.newCall(request).execute();`,
      php: `<?php

    $request = new HttpRequest();
    $request->setUrl('https://astapi.com/api/menu/post/item');
    $request->setMethod(HTTP_METH_POST);

    $request->setHeaders(array(
      'cache-control' => 'no-cache',
      'Connection' => 'keep-alive',
      'Accept-Encoding' => 'gzip, deflate',
      'Host' => 'astapi.com',
      'Cache-Control' => 'no-cache',
      'Accept' => '*/*',
      'Content-Type' => 'application/json',
      'Authorization' => '${token}'
    ));

    $request->setBody('{
      "class": "{class}",
      "name": "{name}",
      "break_price": {breakPrice},
      "lunch_price": {lunchPrice},
      "dinner_price": {dinnerPrice},
      "special_price": {specialPrice},
      "happy_hour": {happyHour},
      "midnight_price": {midnightPrice},
      "searchid": "{searchId}"
    }');

    try {
      $response = $request->send();

      echo $response->getBody();
    } catch (HttpException $ex) {
      echo $ex;
    }
    ?>`,
      curl: `curl -X POST \
    https://astapi.com/api/menu/post/item \
    -H 'Accept: */*' \
    -H 'Accept-Encoding: gzip, deflate' \
    -H 'Authorization: ${token}' \
    -H 'Cache-Control: no-cache' \
    -H 'Connection: keep-alive' \
    -H 'Content-Type: application/json' \
    -H 'Host: astapi.com' \
    -H 'cache-control: no-cache' \
    -d '{
      "class": "{class}",
      "name": "{name}",
      "break_price": {breakPrice},
      "lunch_price": {lunchPrice},
      "dinner_price": {dinnerPrice},
      "special_price": {specialPrice},
      "happy_hour": {happyHour},
      "midnight_price": {midnightPrice},
      "searchid": "{searchId}"
  }'`
    }
  }
});
