import React from 'react';

// accordian imports
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import Collapse from 'react-bootstrap/Collapse';

// npm react-numeric-input
import NumericInput from 'react-numeric-input';
// checkbox import
import Button from 'react-bootstrap/Button';
import ServiceModifierPage from './service_modifiers';



class AccordionPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
        };
    }

    render() {
        const { open } = this.state;

        const serviceClassItems = [
            {
                id: 1,
                title: "service class item #1"     
            },
            {
                id: 3,
                title: "service class item #2"     
            },
            {
                id: 3,
                title: "service class item #3"     
            }
        ] 


        return(
            <Accordion>
                {serviceClassItems.map((serviceClassItem, serviceClassIndex) => (
                    <Card key={serviceClassIndex}>
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ open: !open })} aria-controls="collapse-card" aria-expanded={open}>
                                {serviceClassItem.title}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0" in={this.state.open}>
                                    <ServiceModifierPage />
                            </Accordion.Collapse>
                            <Card.Footer>
                                <div className="text-right">
                                    Qty: <NumericInput className="form-control"/>
                                    <Button  size="sm">Add Service</Button>
                                </div>
                            </Card.Footer>
                    </Card>
                ))}
            <hr />
            </Accordion>
        );
    }
}

export default AccordionPage;