import React from 'react';
import {
 MDBCol, MDBBtn, MDBModal, MDBModalBody
} from 'mdbreact';

const styles = {
  validateBtn: {
    fontSize: '1.2rem'
  }
};

const AuthModal = props => {
  const toggle = () => {};

  const { checkAuth } = props;

  return (
    <MDBModal isOpen toggle={toggle}>
      <MDBModalBody>
        <MDBCol>
          <>
            <h2 className="text-center deep-orange-text font-weight-bold pt-4 pb-2">
              Please enter the code sent to:
            </h2>
            <h2 className="text-center text-info font-weight-bold pb-2">phone number</h2>
            <br />
            <div id="auth-input">
              <div id="auth-form">
                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <input type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                <MDBBtn
                  color="info"
                  type="submit"
                  style={styles.validateBtn}
                  className="px-2 py-2 mt-4 mb-2 text-center"
                  onClick={() => {
                    checkAuth();
                  }}
                >
                  Validate
                </MDBBtn>
              </div>
            </div>
          </>
        </MDBCol>
      </MDBModalBody>
    </MDBModal>
  );
};

export default AuthModal;
