import React from 'react';
// import { MDBMedia } from "mdbreact";
import { BrowserRouter as Link } from 'react-router-dom';
// bootstrap imports
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ResAPI from '../../utils/reservations/ResAPI';
// import MenuAPI from '../../utils/menu/MenuAPI';

class ListGroupPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      id: 0,
      title: "",
      name: "",
      address: ""
    };
  }

  storeInfo = async() => {
   const dataItems = await ResAPI.getStores();
   console.log('locations', dataItems);
   this.setState({
     stores: dataItems, 
   })
  }
 
  componentDidMount(){
    this.storeInfo();
  }

  render() {
    const {stores} = this.state
    console.log("current state", this.state);
    return (
      <div>
        <Container style={{ marginTop: '4rem' }}>
          <h1 style={{ marginBottom: '2rem', marginTop: '2rem' }} className="text-center">
            Pick a Location
          </h1>
          <hr />
          {stores && stores.map((store, index) => (
            <Card key={index} className="text-center">
              <Card.Body>
                <Card.Title>{store.name}</Card.Title>
                <Card.Text>{store.title}</Card.Text>
                <Card.Text>{store.address}</Card.Text>
                <Link to="/service">
                  <Button variant="primary">Select</Button>
                </Link>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </div>
      
    )
  }
    
  
};

export default ListGroupPage;
