/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
const itemGetParser = items => {
  const categories = [];
  items.forEach((item, categoryIndex) => {
    const categoryName = item.class;
    const subCategoryName = item.subclass || 'NONE';

    const lastCategoryName = categoryIndex && categories[categories.length - 1].name;

    if (categoryName !== lastCategoryName) {
      categories.push({
        name: categoryName,
        subCategories: [{ name: subCategoryName, items: [] }]
      });
    }

    if (categoryIndex) {
      const lastSubCategoryName = categoryIndex
        && categories[categories.length - 1].subCategories[
          categories[categories.length - 1].subCategories.length - 1
        ].name;

      if (subCategoryName !== lastSubCategoryName) {
        categories[categories.length - 1].subCategories.push({ name: subCategoryName, items: [] });
      }
    }

    const itemFieldParser = (itemField, cb) => {
      let parsedItemField;
      let parsedItemFieldInt;
      if (itemField === 'NULL') {
        parsedItemField = null;
        cb(parsedItemField);
      } else if (itemField.length > 1) {
        parsedItemField = itemField.trim().slice(itemField.length - 1, itemField.length);
        parsedItemFieldInt = parseInt(parsedItemField, 10);
        cb(parsedItemFieldInt);
      } else {
        parsedItemFieldInt = parseInt(itemField, 10);
        cb(parsedItemFieldInt);
      }
    };

    let course = null;
    if (item.course) {
      itemFieldParser(item.course, parsedItemField => {
        course = parsedItemField;
      });
    }

    let printer = 1;
    if (item.cook_printer) {
      itemFieldParser(item.cook_printer, parsedItemField => {
        printer = parsedItemField;
      });
    }

    let cookScreen = 1;
    if (item.cook_screen) {
      itemFieldParser(item.cook_screen, parsedItemField => {
        cookScreen = parsedItemField;
      });
    }

    let expoPrinter = 1;
    if (item.expo_printer) {
      itemFieldParser(item.expo_printer, parsedItemField => {
        expoPrinter = parsedItemField;
      });
    }

    let labelPrinter = null;
    if (item.labelprint_status) {
      itemFieldParser(item.labelprint_status, parsedItemField => {
        labelPrinter = parsedItemField;
      });
    }

    let belongsTo = {};
    if (item.module) {
      belongsTo = {
        // Char Code 48 = 0, 49 = 1
        tableService: item.module.charCodeAt(0) === 49,
        quickServe: item.module.charCodeAt(1) === 49,
        driveThru: item.module.charCodeAt(2) === 49,
        online: item.module.charCodeAt(3) === 49,
        party: item.module.charCodeAt(4) === 49,
        bar: item.module.charCodeAt(5) === 49,
        phoneOrder: item.module.charCodeAt(6) === 49
      };
    } else {
      belongsTo = {
        tableService: true,
        quickServe: true,
        driveThru: false,
        online: false,
        party: false,
        bar: false,
        phoneOrder: true
      };
    }

    const comments = item.comment ? item.comment.split(':') : [];
    const itemObj = {
      id: item.item_id,
      name: item.name,
      price: item.special_price || 0,
      cost: item.cost_price || 0,
      addToInventory: false,
      searchId: item.searchid || '',
      altSearchId: item.alt_searchid || '',
      tax: {
        one: item.taxed1 || false,
        two: item.taxed2 || false,
        three: item.taxed_xtra || false,
        toGo: item.tax_free_togo || false
      },
      course,
      printer,
      cookScreen,
      expoPrinter,
      labelPrinter,
      options: {
        hideOnCart: item.hide_on_cart || false,
        disableDiscount: item.disable_discount || false,
        qtyPrompt: item.qty_prompt || false,
        checkId: item.check_id || false,
        serviceItem: item.service_item || false
      },
      belongsTo,
      comments,
      modifiers: [
        {
          choicesLimit: '',
          forced: false,
          modifier: []
        },
        {
          choicesLimit: '',
          forced: false,
          modifier: [{ name: '', price: '', cost: 0 }]
        },
        {
          choicesLimit: '',
          forced: false,
          modifier: [{ name: '', price: '', cost: 0 }]
        },
        {
          choicesLimit: '',
          forced: false,
          modifier: [{ name: '', cost: 0 }]
        }
      ]
    };

    categories[categories.length - 1].subCategories[
      categories[categories.length - 1].subCategories.length - 1
    ].items.push(itemObj);
  });
  return categories;
};

const modifierGetParser = modifiersRes => {
  const modifiers = [
    {
      choicesLimit: '',
      forced: false,
      modifier: [{ name: '', cost: 0 }]
    },
    {
      choicesLimit: '',
      forced: false,
      modifier: [{ name: '', price: '', cost: 0 }]
    },
    {
      choicesLimit: '',
      forced: false,
      modifier: [{ name: '', price: '', cost: 0 }]
    },
    {
      choicesLimit: '',
      forced: false,
      modifier: [{ name: '', cost: 0 }]
    }
  ];

  try {
    if (modifiersRes.one.hotness) {
      const modifierOneNameArr = modifiersRes.one.hotness && modifiersRes.one.hotness.split(':');
      modifiers[0].modifier = [];
      modifierOneNameArr.forEach(name => {
        modifiers[0].modifier.push({ name, cost: 0 });
      });
      modifiers[0].choicesLimit = modifiersRes.one.hotness_threshold;
      if (modifiersRes.one.hotness_level) {
        modifiersRes.one.hotness_level.toUpperCase() === 'FORCED' && (modifiers[0].forced = true);
      }
    }

    if (modifiersRes.two.quantityTable.length) {
      modifiers[1].modifier = [];
      modifiersRes.two.quantityTable.forEach(({ size, price }) => {
        modifiers[1].modifier.push({ name: size, price: '', cost: price });
      });
      modifiers[1].choicesLimit = modifiersRes.two.itemTable.quantity_threshold;
      if (modifiersRes.two.itemTable.quantity_level) {
        modifiersRes.two.itemTable.quantity_level.toUpperCase() === 'FORCED' && (modifiers[1].forced = true);
      }
    }

    if (modifiersRes.three.extrasTable.length) {
      modifiers[2].modifier = [];
      modifiersRes.three.extrasTable.forEach(({ extras, price }) => {
        modifiers[2].modifier.push({ name: extras, price: '', cost: price });
      });
      modifiers[2].choicesLimit = modifiersRes.three.itemTable.extra_threshold;
      if (modifiersRes.three.itemTable.extras_level) {
        modifiersRes.three.itemTable.extras_level.toUpperCase() === 'FORCED' && (modifiers[2].forced = true);
      }
    }

    if (modifiersRes.four.options) {
      const modifierFourNameArr = modifiersRes.four.options && modifiersRes.four.options.split(':');
      modifiers[3].modifier = [];
      modifierFourNameArr.forEach(name => {
        modifiers[3].modifier.push({ name, cost: 0 });
      });
      modifiers[3].choicesLimit = modifiersRes.four.option_threshold;
      if (modifiersRes.four.options_level) {
        modifiersRes.four.options_level.toUpperCase() === 'FORCED' && (modifiers[3].forced = true);
      }
    }
  } catch (error) {
    console.error('Parser => modifierGetParser: ', error);
  }
  return modifiers;
};

const itemPostParser = (category, subCategory, item) => {
  try {
    let moduleStr = '';
    item.belongsTo.tableService ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.quickServe ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.driveThru ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.online ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.party ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.bar ? (moduleStr += '1') : (moduleStr += '0');
    item.belongsTo.phoneOrder ? (moduleStr += '1') : (moduleStr += '0');

    const parsedItem = {
      item_id: item.id,
      class: category,
      subclass: subCategory,
      name: item.name,
      searchid: item.searchId || '',
      alt_searchid: item.altSearchId || '',
      special_price: item.price,
      break_price: item.breakPrice || item.price,
      lunch_price: item.lunchPrice || item.price,
      dinner_price: item.dinnerPrice || item.price,
      happy_hour: item.happyHourPrice || item.price,
      midnight_price: item.lateNightPrice || item.price,
      cost_price: item.cost,
      // addToInventory: false,
      taxed1: item.tax.one,
      taxed2: item.tax.two,
      taxed_xtra: item.tax.three,
      tax_free_togo: item.tax.toGo,
      course: item.course || 'NONE',
      cook_printer: item.printer || 1,
      cook_screen: item.cookScreen || 1,
      expo_printer: item.expoPrinter || 1,
      labelprint_status: item.labelPrinter || 'NONE',
      hide_on_cart: item.options.hideOnCart,
      disable_discount: item.options.disableDiscount,
      qty_prompt: item.options.qtyPrompt,
      check_id: item.options.checkId,
      service_item: item.options.serviceItem,
      module: moduleStr,
      comment: item.comments ? item.comments.join(':') : null,
      hotness_level: null,
      hotness_threshold: null,
      hotness: null,
      quantity_level: null,
      quantity_threshold: null,
      extras_level: null,
      extra_threshold: null,
      options_level: null,
      option_threshold: null,
      options: null
    };

    // const modifierOne = item.modifiers[0];
    // if (modifierOne.modifier[0].name) {
    //   parsedItem.hotness_level = modifierOne.forced ? 'FORCED' : 'MODIFY';
    //   parsedItem.hotness_threshold = modifierOne.choicesLimit || null;
    //   modifierOne.modifier.forEach(({ name /* cost */ }, modifierIndex) => {
    //     parsedItem.hotness += modifierOne.modifier.length - 1 === modifierIndex ? name : `${name}:`;
    //   });
    // }

    // const modifierTwo = item.modifiers[1];
    // const parsedModifierTwo = [];
    // if (modifierTwo.modifier[0].name) {
    //   parsedItem.quantity_level = modifierTwo.forced ? 'FORCED' : 'MODIFY';
    //   parsedItem.quantity_threshold = modifierTwo.choicesLimit || null;
    //   modifierTwo.modifier.forEach(({ name, price }) => {
    //     parsedModifierTwo.push({ size: name, price });
    //   });
    // }

    // const modifierThree = item.modifiers[2];
    // const parsedModifierThree = [];
    // if (modifierThree.modifier[0].name) {
    //   parsedItem.extras_level = modifierThree.forced ? 'FORCED' : 'MODIFY';
    //   parsedItem.extra_threshold = modifierThree.choicesLimit || null;
    //   modifierThree.modifier.forEach(({ name, price }) => {
    //     parsedModifierThree.push({ extras: name, price });
    //   });
    // }

    // const modifierFour = item.modifiers[3];
    // if (modifierFour.modifier[0].name) {
    //   parsedItem.options_level = modifierFour.forced ? 'FORCED' : 'MODIFY';
    //   parsedItem.option_threshold = modifierFour.choicesLimit || null;
    //   modifierFour.modifier.forEach(({ name /* cost */ }, modifierIndex) => {
    //     parsedItem.hotness += modifierFour.modifier.length - 1 === modifierIndex ? name : `${name}:`;
    //   });
    // }
    return { parsedItem /* parsedModifierTwo, parsedModifierThree */ };
  } catch (error) {
    console.error('Parser => itemPostParser: ', error);
  }
};

const logInParser = storeInfo => {
  const { lastLogInOne, lastLogInTwo, lastLogInThree } = storeInfo;
  const lastLogInOneStr = Date.parse(lastLogInOne);
  const lastLogInTwoStr = Date.parse(lastLogInTwo);
  const lastLogInThreeStr = Date.parse(lastLogInThree);
  const newestLogInStr = Math.max(lastLogInOneStr, lastLogInTwoStr, lastLogInThreeStr);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  let newestLogIn;
  console.log(lastLogInOneStr,
lastLogInTwoStr,
lastLogInThreeStr)
  switch (newestLogInStr) {
    case lastLogInOneStr:
      newestLogIn = new Date(lastLogInOneStr).toLocaleDateString('en-US', options);
      break;
    case lastLogInTwoStr:
      newestLogIn = new Date(lastLogInTwoStr).toLocaleDateString('en-US', options);
      break;
    case lastLogInThreeStr:
      newestLogIn = new Date(lastLogInThreeStr).toLocaleDateString('en-US', options);
      break;
    default:
      // newestLogIn = new Date();
      // throw new Error('Invalid new date');
  }

  delete storeInfo.lastLogInOne;
  delete storeInfo.lastLogInTwo;
  delete storeInfo.lastLogInThree;
  storeInfo.newestLogIn = newestLogIn;
  return storeInfo;
};

export {
 itemGetParser, itemPostParser, modifierGetParser, logInParser
};
