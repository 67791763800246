import React, { Component } from 'react';
import {
 MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBInput
} from 'mdbreact';

const styles = {
  heading: {
    fontWeight: '500'
  },
  input: {
    fontWeight: '900'
  },
  btnAdd: {
    fontWeight: '900',
    fontSize: '1rem'
  }
};

class MenuCategoryForm extends Component {
  // eslint-disable-next-line react/destructuring-assignment
  state = { category: this.props.editingCategory || '', edited: false };

  handleChange = ({ target }) => {
    const valueUp = target.value.toUpperCase();
    this.setState({ category: valueUp });
  };

  setCategory = e => {
    e.preventDefault();
    e.target.className += ' was-validated';
    const { category } = this.state;
    const { menuBuilderSetCategory } = this.props;
    const categoryTrim = category.trim().toUpperCase();
    menuBuilderSetCategory(categoryTrim);
  };

  header = () => {
    const { editingCategory, storeName, menuLoaded } = this.props;
    if (menuLoaded) {
      if (editingCategory) {
        return 'Edit Category';
      }
      return 'Add Menu Categories';
    }
    return (
      <>
        <span className="h4 deep-orange-text">{storeName}</span> <br />
        we are loading your menu...
        <br />
        <br />
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </>
    );
  };

  render() {
    const { editingCategory, menuLoaded } = this.props;
    const { category } = this.state;

    return (
      <MDBCard style={styles.card}>
        <MDBCardBody>
          <form className="needs-validation" onSubmit={this.setCategory} noValidate>
            <h2 className="text-center text-info py-4" style={styles.heading}>
              {this.header()}
            </h2>
            {menuLoaded && (
              <MDBCol>
                <MDBInput
                  label="Category"
                  size="lg"
                  id="categoryFormName"
                  type="text"
                  name="menuCategory"
                  value={category}
                  onChange={this.handleChange}
                  style={styles.input}
                  minLength="3"
                  error="Please enter a Category name longer than 3 letters"
                  required
                >
                  <div className="invalid-feedback">Please enter a Category name longer than 3 letters</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
                <MDBCol className="d-flex justify-content-center">
                  <MDBBtn
                    disabled={category.trim().length <= 3}
                    outline
                    color="success"
                    type="submit"
                    style={styles.btnAdd}
                    size="sm"
                  >
                    {editingCategory ? 'Edit' : 'Add'}
                    <MDBIcon far icon="thumbs-up" className="ml-2" size="lg" />
                  </MDBBtn>
                </MDBCol>
              </MDBCol>
            )}
          </form>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default MenuCategoryForm;
